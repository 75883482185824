import "image/image.css";
import "image/icon.css";
import "tailwindcss/tailwind.css";
import "devtools/client/debugger/src/components/variables.css";
import "replay-next/pages/variables.css";
import "devtools/client/themes/variables.css";
import "use-context-menu/styles.css";

/////////////////// here be dragons ... ////////////////////
import "devtools/client/debugger/src/components/A11yIntention.css";
import "devtools/client/debugger/src/components/App.css";
import "devtools/client/debugger/src/components/Editor/Breakpoints/Breakpoints.css";
import "devtools/client/debugger/src/components/Editor/Breakpoints/Panel/Panel.css";
import "devtools/client/debugger/src/components/Editor/Editor.css";
import "devtools/client/debugger/src/components/Editor/Footer.css";
import "devtools/client/debugger/src/components/Editor/Tabs.css";
import "devtools/client/debugger/src/components/SourceOutline/Outline.css";
import "devtools/client/debugger/src/components/PrimaryPanes/Sources.css";
import "devtools/client/debugger/src/components/QuickOpenModal.css";
import "devtools/client/debugger/src/components/SecondaryPanes/CommandBar.css";
import "devtools/client/debugger/src/components/SecondaryPanes/Frames/Frames.css";
import "devtools/client/debugger/src/components/SecondaryPanes/Frames/Group.css";
import "devtools/client/debugger/src/components/SecondaryPanes/FrameTimeline.css";
import "devtools/client/debugger/src/components/SecondaryPanes/Scopes.css";
import "devtools/client/debugger/src/components/SecondaryPanes/SecondaryPanes.css";
import "devtools/client/debugger/src/components/shared/AccessibleImage.css";
import "devtools/client/debugger/src/components/shared/Accordion.css";
import "devtools/client/debugger/src/components/shared/Badge.css";
import "devtools/client/debugger/src/components/shared/BracketArrow.css";
import "devtools/client/debugger/src/components/shared/Button/CloseButton.css";
import "devtools/client/debugger/src/components/shared/Button/CommandBarButton.css";
import "devtools/client/debugger/src/components/shared/Dropdown.css";
import "devtools/client/debugger/src/components/shared/ManagedTree.css";
import "devtools/client/debugger/src/components/shared/menu.css";
import "devtools/client/debugger/src/components/shared/Modal.css";
import "devtools/client/debugger/src/components/shared/PreviewFunction.css";
import "devtools/client/debugger/src/components/shared/ResultList.css";
import "devtools/client/debugger/src/components/shared/SearchInput.css";
import "devtools/client/debugger/src/components/shared/SourceIcon.css";
import "devtools/client/debugger/src/components/shared/tree.css";
import "devtools/client/debugger/src/components/ShortcutsModal.css";
import "devtools/client/shared/components/Accordion.css";
import "devtools/client/shared/components/splitter/SplitBox.css";
import "devtools/client/shared/components/Tabs.css";
import "devtools/client/themes/accessibility-color-contrast.css";
import "devtools/client/themes/badge.css";
import "devtools/client/themes/boxmodel.css";
import "devtools/client/themes/breadcrumbs.css";
import "devtools/client/themes/changes.css";
import "devtools/client/themes/common.css";
import "devtools/client/themes/computed.css";
import "devtools/client/themes/inspector.css";
import "devtools/client/themes/layout.css";
import "devtools/client/themes/splitters.css";
import "devtools/client/themes/toolbars.css";
import "devtools/client/themes/tooltips.css";
import "devtools/client/themes/webconsole.css";
import "devtools/server/actors/highlighters.css";
import "ui/components/App.css";
import "ui/components/Comments/Comments.css";
import "ui/components/Comments/VideoComments/CommentsOverlay.css";
import "ui/components/Header/UserOptions.css";
import "ui/components/Header/ViewToggle.css";
import "ui/components/reactjs-popup.css";
import "ui/components/Events/Events.css";
import "ui/components/SecondaryToolbox/SecondaryToolbox.css";
import "ui/components/shared/Dialog.css";
import "ui/components/shared/Dropdown.css";
import "ui/components/shared/LoginModal.css";
import "ui/components/shared/Modal.css";
import "ui/components/shared/PortalDropdown.css";
import "ui/components/shared/SettingsModal/SettingsBody.css";
import "ui/components/shared/SettingsModal/SettingsModal.css";
import "ui/components/shared/SettingsModal/SettingsNavigation.css";
import "ui/components/shared/SharingModal/CollaboratorsList.css";
import "ui/components/shared/SharingModal/EmailForm.css";
import "ui/components/shared/WorkspaceSettingsModal/WorkspaceMember.css";
import "ui/components/Timeline/MessageMarker.css";
import "ui/components/Timeline/Timeline.css";
import "ui/components/Timeline/Tooltip.css";
import "ui/components/Toolbox.css";
import "packages/third-party/sourcemap-visualizer/sourcemapVisualizer.css";
/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

// Produced by logging the output of generateCssProperties() in actors/css-properties.js
const properties = {
  "align-content": {
    isInherited: false,
    values: [
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "normal",
      "revert",
      "safe",
      "space-around",
      "space-between",
      "space-evenly",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["align-content"],
  },
  "align-items": {
    isInherited: false,
    values: [
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "normal",
      "revert",
      "safe",
      "self-end",
      "self-start",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["align-items"],
  },
  "align-self": {
    isInherited: false,
    values: [
      "auto",
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "normal",
      "revert",
      "safe",
      "self-end",
      "self-start",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["align-self"],
  },
  "backface-visibility": {
    isInherited: false,
    values: ["hidden", "inherit", "initial", "revert", "unset", "visible"],
    supports: [],
    subproperties: ["backface-visibility"],
  },
  "border-collapse": {
    isInherited: true,
    values: ["collapse", "inherit", "initial", "revert", "separate", "unset"],
    supports: [],
    subproperties: ["border-collapse"],
  },
  "border-image-repeat": {
    isInherited: false,
    values: ["inherit", "initial", "repeat", "revert", "round", "space", "stretch", "unset"],
    supports: [],
    subproperties: ["border-image-repeat"],
  },
  "box-decoration-break": {
    isInherited: false,
    values: ["clone", "inherit", "initial", "revert", "slice", "unset"],
    supports: [],
    subproperties: ["box-decoration-break"],
  },
  "box-sizing": {
    isInherited: false,
    values: ["border-box", "content-box", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["box-sizing"],
  },
  "break-inside": {
    isInherited: false,
    values: ["auto", "avoid", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["break-inside"],
  },
  "caption-side": {
    isInherited: true,
    values: [
      "bottom",
      "bottom-outside",
      "inherit",
      "initial",
      "left",
      "revert",
      "right",
      "top",
      "top-outside",
      "unset",
    ],
    supports: [],
    subproperties: ["caption-side"],
  },
  clear: {
    isInherited: false,
    values: [
      "both",
      "inherit",
      "initial",
      "inline-end",
      "inline-start",
      "left",
      "none",
      "revert",
      "right",
      "unset",
    ],
    supports: [],
    subproperties: ["clear"],
  },
  "color-adjust": {
    isInherited: true,
    values: ["economy", "exact", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["color-adjust"],
  },
  "color-interpolation": {
    isInherited: true,
    values: ["auto", "inherit", "initial", "linearrgb", "revert", "srgb", "unset"],
    supports: [],
    subproperties: ["color-interpolation"],
  },
  "color-interpolation-filters": {
    isInherited: true,
    values: ["auto", "inherit", "initial", "linearrgb", "revert", "srgb", "unset"],
    supports: [],
    subproperties: ["color-interpolation-filters"],
  },
  "column-count": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["column-count"],
  },
  "column-fill": {
    isInherited: false,
    values: ["auto", "balance", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["column-fill"],
  },
  "column-span": {
    isInherited: false,
    values: ["all", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["column-span"],
  },
  contain: {
    isInherited: false,
    values: [
      "content",
      "inherit",
      "initial",
      "layout",
      "none",
      "paint",
      "revert",
      "size",
      "strict",
      "unset",
    ],
    supports: [],
    subproperties: ["contain"],
  },
  direction: {
    isInherited: true,
    values: ["inherit", "initial", "ltr", "revert", "rtl", "unset"],
    supports: [],
    subproperties: ["direction"],
  },
  display: {
    isInherited: false,
    values: [
      "-webkit-box",
      "-webkit-inline-box",
      "block",
      "block ruby",
      "contents",
      "flex",
      "flow-root",
      "flow-root list-item",
      "grid",
      "inherit",
      "initial",
      "inline",
      "inline flow-root list-item",
      "inline list-item",
      "inline-block",
      "inline-flex",
      "inline-grid",
      "inline-table",
      "list-item",
      "none",
      "revert",
      "ruby",
      "ruby-base",
      "ruby-base-container",
      "ruby-text",
      "ruby-text-container",
      "table",
      "table-caption",
      "table-cell",
      "table-column",
      "table-column-group",
      "table-footer-group",
      "table-header-group",
      "table-row",
      "table-row-group",
      "unset",
    ],
    supports: [],
    subproperties: ["display"],
  },
  "dominant-baseline": {
    isInherited: true,
    values: [
      "alphabetic",
      "auto",
      "central",
      "hanging",
      "ideographic",
      "inherit",
      "initial",
      "mathematical",
      "middle",
      "revert",
      "text-after-edge",
      "text-before-edge",
      "unset",
    ],
    supports: [],
    subproperties: ["dominant-baseline"],
  },
  "empty-cells": {
    isInherited: true,
    values: ["hide", "inherit", "initial", "revert", "show", "unset"],
    supports: [],
    subproperties: ["empty-cells"],
  },
  "flex-direction": {
    isInherited: false,
    values: [
      "column",
      "column-reverse",
      "inherit",
      "initial",
      "revert",
      "row",
      "row-reverse",
      "unset",
    ],
    supports: [],
    subproperties: ["flex-direction"],
  },
  "flex-wrap": {
    isInherited: false,
    values: ["inherit", "initial", "nowrap", "revert", "unset", "wrap", "wrap-reverse"],
    supports: [],
    subproperties: ["flex-wrap"],
  },
  float: {
    isInherited: false,
    values: [
      "inherit",
      "initial",
      "inline-end",
      "inline-start",
      "left",
      "none",
      "revert",
      "right",
      "unset",
    ],
    supports: [],
    subproperties: ["float"],
  },
  "font-kerning": {
    isInherited: true,
    values: ["auto", "inherit", "initial", "none", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["font-kerning"],
  },
  "font-optical-sizing": {
    isInherited: true,
    values: ["auto", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["font-optical-sizing"],
  },
  "font-size-adjust": {
    isInherited: true,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["font-size-adjust"],
  },
  "font-stretch": {
    isInherited: true,
    values: [
      "condensed",
      "expanded",
      "extra-condensed",
      "extra-expanded",
      "inherit",
      "initial",
      "normal",
      "revert",
      "semi-condensed",
      "semi-expanded",
      "ultra-condensed",
      "ultra-expanded",
      "unset",
    ],
    supports: [],
    subproperties: ["font-stretch"],
  },
  "font-style": {
    isInherited: true,
    values: ["inherit", "initial", "italic", "normal", "oblique", "revert", "unset"],
    supports: [],
    subproperties: ["font-style"],
  },
  "font-synthesis": {
    isInherited: true,
    values: ["inherit", "initial", "revert", "style", "unset", "weight"],
    supports: [],
    subproperties: ["font-synthesis"],
  },
  "font-variant-caps": {
    isInherited: true,
    values: [
      "all-petite-caps",
      "all-small-caps",
      "inherit",
      "initial",
      "normal",
      "petite-caps",
      "revert",
      "small-caps",
      "titling-caps",
      "unicase",
      "unset",
    ],
    supports: [],
    subproperties: ["font-variant-caps"],
  },
  "font-variant-east-asian": {
    isInherited: true,
    values: [
      "full-width",
      "inherit",
      "initial",
      "jis04",
      "jis78",
      "jis83",
      "jis90",
      "normal",
      "proportional-width",
      "revert",
      "ruby",
      "simplified",
      "traditional",
      "unset",
    ],
    supports: [],
    subproperties: ["font-variant-east-asian"],
  },
  "font-variant-ligatures": {
    isInherited: true,
    values: [
      "common-ligatures",
      "contextual",
      "discretionary-ligatures",
      "historical-ligatures",
      "inherit",
      "initial",
      "no-common-ligatures",
      "no-contextual",
      "no-discretionary-ligatures",
      "no-historical-ligatures",
      "none",
      "normal",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["font-variant-ligatures"],
  },
  "font-variant-numeric": {
    isInherited: true,
    values: [
      "diagonal-fractions",
      "inherit",
      "initial",
      "lining-nums",
      "normal",
      "oldstyle-nums",
      "ordinal",
      "proportional-nums",
      "revert",
      "slashed-zero",
      "stacked-fractions",
      "tabular-nums",
      "unset",
    ],
    supports: [],
    subproperties: ["font-variant-numeric"],
  },
  "font-variant-position": {
    isInherited: true,
    values: ["inherit", "initial", "normal", "revert", "sub", "super", "unset"],
    supports: [],
    subproperties: ["font-variant-position"],
  },
  "font-weight": {
    isInherited: true,
    values: ["bold", "bolder", "inherit", "initial", "lighter", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["font-weight"],
  },
  "grid-auto-flow": {
    isInherited: false,
    values: ["column", "dense", "inherit", "initial", "revert", "row", "unset"],
    supports: [],
    subproperties: ["grid-auto-flow"],
  },
  hyphens: {
    isInherited: true,
    values: ["auto", "inherit", "initial", "manual", "none", "revert", "unset"],
    supports: [],
    subproperties: ["hyphens"],
  },
  "image-orientation": {
    isInherited: true,
    values: ["from-image", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["image-orientation"],
  },
  "image-rendering": {
    isInherited: true,
    values: [
      "-moz-crisp-edges",
      "auto",
      "crisp-edges",
      "inherit",
      "initial",
      "optimizequality",
      "optimizespeed",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["image-rendering"],
  },
  "ime-mode": {
    isInherited: false,
    values: [
      "active",
      "auto",
      "disabled",
      "inactive",
      "inherit",
      "initial",
      "normal",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["ime-mode"],
  },
  isolation: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "isolate", "revert", "unset"],
    supports: [],
    subproperties: ["isolation"],
  },
  "justify-content": {
    isInherited: false,
    values: [
      "center",
      "end",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "left",
      "normal",
      "revert",
      "right",
      "safe",
      "space-around",
      "space-between",
      "space-evenly",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["justify-content"],
  },
  "justify-items": {
    isInherited: false,
    values: [
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "left",
      "legacy",
      "normal",
      "revert",
      "right",
      "safe",
      "self-end",
      "self-start",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["justify-items"],
  },
  "justify-self": {
    isInherited: false,
    values: [
      "auto",
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "left",
      "normal",
      "revert",
      "right",
      "safe",
      "self-end",
      "self-start",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["justify-self"],
  },
  "line-break": {
    isInherited: true,
    values: [
      "anywhere",
      "auto",
      "inherit",
      "initial",
      "loose",
      "normal",
      "revert",
      "strict",
      "unset",
    ],
    supports: [],
    subproperties: ["line-break"],
  },
  "list-style-position": {
    isInherited: true,
    values: ["inherit", "initial", "inside", "outside", "revert", "unset"],
    supports: [],
    subproperties: ["list-style-position"],
  },
  "mask-type": {
    isInherited: false,
    values: ["alpha", "inherit", "initial", "luminance", "revert", "unset"],
    supports: [],
    subproperties: ["mask-type"],
  },
  "mix-blend-mode": {
    isInherited: false,
    values: [
      "color",
      "color-burn",
      "color-dodge",
      "darken",
      "difference",
      "exclusion",
      "hard-light",
      "hue",
      "inherit",
      "initial",
      "lighten",
      "luminosity",
      "multiply",
      "normal",
      "overlay",
      "revert",
      "saturation",
      "screen",
      "soft-light",
      "unset",
    ],
    supports: [],
    subproperties: ["mix-blend-mode"],
  },
  "-moz-appearance": {
    isInherited: false,
    values: [
      "-moz-gtk-info-bar",
      "-moz-mac-active-source-list-selection",
      "-moz-mac-disclosure-button-closed",
      "-moz-mac-disclosure-button-open",
      "-moz-mac-fullscreen-button",
      "-moz-mac-help-button",
      "-moz-mac-source-list",
      "-moz-mac-source-list-selection",
      "-moz-mac-vibrancy-dark",
      "-moz-mac-vibrancy-light",
      "-moz-mac-vibrant-titlebar-dark",
      "-moz-mac-vibrant-titlebar-light",
      "-moz-menulist-arrow-button",
      "-moz-win-borderless-glass",
      "-moz-win-browsertabbar-toolbox",
      "-moz-win-communications-toolbox",
      "-moz-win-exclude-glass",
      "-moz-win-glass",
      "-moz-win-media-toolbox",
      "-moz-window-button-box",
      "-moz-window-button-box-maximized",
      "-moz-window-button-close",
      "-moz-window-button-maximize",
      "-moz-window-button-minimize",
      "-moz-window-button-restore",
      "-moz-window-frame-bottom",
      "-moz-window-frame-left",
      "-moz-window-frame-right",
      "-moz-window-titlebar",
      "-moz-window-titlebar-maximized",
      "button",
      "button-arrow-down",
      "button-arrow-next",
      "button-arrow-previous",
      "button-arrow-up",
      "button-focus",
      "checkbox",
      "checkbox-container",
      "checkbox-label",
      "checkmenuitem",
      "dialog",
      "dualbutton",
      "groupbox",
      "inherit",
      "initial",
      "inner-spin-button",
      "listbox",
      "menuarrow",
      "menubar",
      "menucheckbox",
      "menuimage",
      "menuitem",
      "menuitemtext",
      "menulist",
      "menulist-button",
      "menulist-text",
      "menupopup",
      "menuradio",
      "menuseparator",
      "meter",
      "meterbar",
      "meterchunk",
      "none",
      "number-input",
      "progress-bar",
      "progressbar",
      "progressbar-vertical",
      "progresschunk",
      "radio",
      "radio-container",
      "radio-label",
      "radiomenuitem",
      "range",
      "range-thumb",
      "resizer",
      "resizerpanel",
      "revert",
      "scale-horizontal",
      "scale-vertical",
      "scalethumb-horizontal",
      "scalethumb-vertical",
      "scalethumbend",
      "scalethumbstart",
      "scalethumbtick",
      "scrollbar",
      "scrollbar-horizontal",
      "scrollbar-small",
      "scrollbar-vertical",
      "scrollbarbutton-down",
      "scrollbarbutton-left",
      "scrollbarbutton-right",
      "scrollbarbutton-up",
      "scrollbarthumb-horizontal",
      "scrollbarthumb-vertical",
      "scrollbartrack-horizontal",
      "scrollbartrack-vertical",
      "scrollcorner",
      "searchfield",
      "separator",
      "spinner",
      "spinner-downbutton",
      "spinner-textfield",
      "spinner-upbutton",
      "splitter",
      "statusbar",
      "statusbarpanel",
      "tab",
      "tab-scroll-arrow-back",
      "tab-scroll-arrow-forward",
      "tabpanel",
      "tabpanels",
      "textarea",
      "textfield",
      "textfield-multiline",
      "toolbar",
      "toolbarbutton",
      "toolbarbutton-dropdown",
      "toolbargripper",
      "toolbox",
      "tooltip",
      "treeheader",
      "treeheadercell",
      "treeheadersortarrow",
      "treeitem",
      "treeline",
      "treetwisty",
      "treetwistyopen",
      "treeview",
      "unset",
      "window",
    ],
    supports: [],
    subproperties: ["-moz-appearance"],
  },
  "-moz-box-align": {
    isInherited: false,
    values: [
      "baseline",
      "center",
      "end",
      "inherit",
      "initial",
      "revert",
      "start",
      "stretch",
      "unset",
    ],
    supports: [],
    subproperties: ["-moz-box-align"],
  },
  "-moz-box-direction": {
    isInherited: false,
    values: ["inherit", "initial", "normal", "reverse", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-box-direction"],
  },
  "-moz-box-orient": {
    isInherited: false,
    values: [
      "block-axis",
      "horizontal",
      "inherit",
      "initial",
      "inline-axis",
      "revert",
      "unset",
      "vertical",
    ],
    supports: [],
    subproperties: ["-moz-box-orient"],
  },
  "-moz-box-pack": {
    isInherited: false,
    values: ["center", "end", "inherit", "initial", "justify", "revert", "start", "unset"],
    supports: [],
    subproperties: ["-moz-box-pack"],
  },
  "-moz-control-character-visibility": {
    isInherited: true,
    values: ["hidden", "inherit", "initial", "revert", "unset", "visible"],
    supports: [],
    subproperties: ["-moz-control-character-visibility"],
  },
  "-moz-float-edge": {
    isInherited: false,
    values: ["content-box", "inherit", "initial", "margin-box", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-float-edge"],
  },
  "-moz-force-broken-image-icon": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-force-broken-image-icon"],
  },
  "-moz-orient": {
    isInherited: false,
    values: ["block", "horizontal", "inherit", "initial", "inline", "revert", "unset", "vertical"],
    supports: [],
    subproperties: ["-moz-orient"],
  },
  "-moz-osx-font-smoothing": {
    isInherited: true,
    values: ["auto", "grayscale", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-osx-font-smoothing"],
  },
  "-moz-text-size-adjust": {
    isInherited: true,
    values: ["auto", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-text-size-adjust"],
  },
  "-moz-user-focus": {
    isInherited: true,
    values: [
      "ignore",
      "inherit",
      "initial",
      "none",
      "normal",
      "revert",
      "select-after",
      "select-all",
      "select-before",
      "select-menu",
      "select-same",
      "unset",
    ],
    supports: [],
    subproperties: ["-moz-user-focus"],
  },
  "-moz-user-input": {
    isInherited: true,
    values: ["auto", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-user-input"],
  },
  "-moz-user-modify": {
    isInherited: true,
    values: ["inherit", "initial", "read-only", "read-write", "revert", "unset", "write-only"],
    supports: [],
    subproperties: ["-moz-user-modify"],
  },
  "-moz-window-dragging": {
    isInherited: false,
    values: ["default", "drag", "inherit", "initial", "no-drag", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-window-dragging"],
  },
  "object-fit": {
    isInherited: false,
    values: [
      "contain",
      "cover",
      "fill",
      "inherit",
      "initial",
      "none",
      "revert",
      "scale-down",
      "unset",
    ],
    supports: [],
    subproperties: ["object-fit"],
  },
  "offset-rotate": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "reverse", "revert", "unset"],
    supports: [],
    subproperties: ["offset-rotate"],
  },
  "outline-style": {
    isInherited: false,
    values: [
      "auto",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["outline-style"],
  },
  "overflow-anchor": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["overflow-anchor"],
  },
  "overflow-wrap": {
    isInherited: true,
    values: ["anywhere", "break-word", "inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["overflow-wrap"],
  },
  "paint-order": {
    isInherited: true,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["paint-order"],
  },
  "pointer-events": {
    isInherited: true,
    values: [
      "all",
      "auto",
      "fill",
      "inherit",
      "initial",
      "none",
      "painted",
      "revert",
      "stroke",
      "unset",
      "visible",
      "visiblefill",
      "visiblepainted",
      "visiblestroke",
    ],
    supports: [],
    subproperties: ["pointer-events"],
  },
  position: {
    isInherited: false,
    values: [
      "absolute",
      "fixed",
      "inherit",
      "initial",
      "relative",
      "revert",
      "static",
      "sticky",
      "unset",
    ],
    supports: [],
    subproperties: ["position"],
  },
  resize: {
    isInherited: false,
    values: [
      "block",
      "both",
      "horizontal",
      "inherit",
      "initial",
      "inline",
      "none",
      "revert",
      "unset",
      "vertical",
    ],
    supports: [],
    subproperties: ["resize"],
  },
  "ruby-align": {
    isInherited: true,
    values: [
      "center",
      "inherit",
      "initial",
      "revert",
      "space-around",
      "space-between",
      "start",
      "unset",
    ],
    supports: [],
    subproperties: ["ruby-align"],
  },
  "ruby-position": {
    isInherited: true,
    values: ["inherit", "initial", "over", "revert", "under", "unset"],
    supports: [],
    subproperties: ["ruby-position"],
  },
  "scroll-behavior": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "smooth", "unset"],
    supports: [],
    subproperties: ["scroll-behavior"],
  },
  "scroll-snap-align": {
    isInherited: false,
    values: ["center", "end", "inherit", "initial", "none", "revert", "start", "unset"],
    supports: [],
    subproperties: ["scroll-snap-align"],
  },
  "scroll-snap-type": {
    isInherited: false,
    values: [
      "block",
      "both",
      "inherit",
      "initial",
      "inline",
      "mandatory",
      "proximity",
      "revert",
      "unset",
      "x",
      "y",
    ],
    supports: [],
    subproperties: ["scroll-snap-type"],
  },
  "scrollbar-width": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "none", "revert", "thin", "unset"],
    supports: [],
    subproperties: ["scrollbar-width"],
  },
  "shape-rendering": {
    isInherited: true,
    values: [
      "auto",
      "crispedges",
      "geometricprecision",
      "inherit",
      "initial",
      "optimizespeed",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["shape-rendering"],
  },
  "stroke-linecap": {
    isInherited: true,
    values: ["butt", "inherit", "initial", "revert", "round", "square", "unset"],
    supports: [],
    subproperties: ["stroke-linecap"],
  },
  "stroke-linejoin": {
    isInherited: true,
    values: ["bevel", "inherit", "initial", "miter", "revert", "round", "unset"],
    supports: [],
    subproperties: ["stroke-linejoin"],
  },
  "table-layout": {
    isInherited: false,
    values: ["auto", "fixed", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["table-layout"],
  },
  "text-align": {
    isInherited: true,
    values: [
      "-moz-center",
      "-moz-left",
      "-moz-right",
      "center",
      "end",
      "inherit",
      "initial",
      "justify",
      "left",
      "match-parent",
      "revert",
      "right",
      "start",
      "unset",
    ],
    supports: [],
    subproperties: ["text-align"],
  },
  "text-align-last": {
    isInherited: true,
    values: [
      "auto",
      "center",
      "end",
      "inherit",
      "initial",
      "justify",
      "left",
      "revert",
      "right",
      "start",
      "unset",
    ],
    supports: [],
    subproperties: ["text-align-last"],
  },
  "text-anchor": {
    isInherited: true,
    values: ["end", "inherit", "initial", "middle", "revert", "start", "unset"],
    supports: [],
    subproperties: ["text-anchor"],
  },
  "text-combine-upright": {
    isInherited: true,
    values: ["all", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["text-combine-upright"],
  },
  "text-decoration-line": {
    isInherited: false,
    values: [
      "blink",
      "inherit",
      "initial",
      "line-through",
      "none",
      "overline",
      "revert",
      "underline",
      "unset",
    ],
    supports: [],
    subproperties: ["text-decoration-line"],
  },
  "text-decoration-skip-ink": {
    isInherited: true,
    values: ["all", "auto", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["text-decoration-skip-ink"],
  },
  "text-decoration-style": {
    isInherited: false,
    values: [
      "-moz-none",
      "dashed",
      "dotted",
      "double",
      "inherit",
      "initial",
      "revert",
      "solid",
      "unset",
      "wavy",
    ],
    supports: [],
    subproperties: ["text-decoration-style"],
  },
  "text-emphasis-position": {
    isInherited: true,
    values: ["inherit", "initial", "left", "over", "revert", "right", "under", "unset"],
    supports: [],
    subproperties: ["text-emphasis-position"],
  },
  "text-justify": {
    isInherited: true,
    values: [
      "auto",
      "distribute",
      "inherit",
      "initial",
      "inter-character",
      "inter-word",
      "none",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["text-justify"],
  },
  "text-orientation": {
    isInherited: true,
    values: [
      "inherit",
      "initial",
      "mixed",
      "revert",
      "sideways",
      "sideways-right",
      "unset",
      "upright",
    ],
    supports: [],
    subproperties: ["text-orientation"],
  },
  "text-rendering": {
    isInherited: true,
    values: [
      "auto",
      "geometricprecision",
      "inherit",
      "initial",
      "optimizelegibility",
      "optimizespeed",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["text-rendering"],
  },
  "text-transform": {
    isInherited: true,
    values: [
      "capitalize",
      "full-size-kana",
      "full-width",
      "inherit",
      "initial",
      "lowercase",
      "none",
      "revert",
      "unset",
      "uppercase",
    ],
    supports: [],
    subproperties: ["text-transform"],
  },
  "text-underline-position": {
    isInherited: true,
    values: [
      "auto",
      "from-font",
      "inherit",
      "initial",
      "left",
      "revert",
      "right",
      "under",
      "unset",
    ],
    supports: [],
    subproperties: ["text-underline-position"],
  },
  "touch-action": {
    isInherited: false,
    values: [
      "auto",
      "inherit",
      "initial",
      "manipulation",
      "none",
      "pan-x",
      "pan-y",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["touch-action"],
  },
  "transform-box": {
    isInherited: false,
    values: ["border-box", "fill-box", "inherit", "initial", "revert", "unset", "view-box"],
    supports: [],
    subproperties: ["transform-box"],
  },
  "transform-style": {
    isInherited: false,
    values: ["flat", "inherit", "initial", "preserve-3d", "revert", "unset"],
    supports: [],
    subproperties: ["transform-style"],
  },
  "unicode-bidi": {
    isInherited: false,
    values: [
      "bidi-override",
      "embed",
      "inherit",
      "initial",
      "isolate",
      "isolate-override",
      "normal",
      "plaintext",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["unicode-bidi"],
  },
  "user-select": {
    isInherited: false,
    values: ["-moz-none", "all", "auto", "inherit", "initial", "none", "revert", "text", "unset"],
    supports: [],
    subproperties: ["user-select"],
  },
  "vector-effect": {
    isInherited: false,
    values: ["inherit", "initial", "non-scaling-stroke", "none", "revert", "unset"],
    supports: [],
    subproperties: ["vector-effect"],
  },
  visibility: {
    isInherited: true,
    values: ["collapse", "hidden", "inherit", "initial", "revert", "unset", "visible"],
    supports: [],
    subproperties: ["visibility"],
  },
  "-webkit-line-clamp": {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["-webkit-line-clamp"],
  },
  "white-space": {
    isInherited: true,
    values: [
      "-moz-pre-space",
      "break-spaces",
      "inherit",
      "initial",
      "normal",
      "nowrap",
      "pre",
      "pre-line",
      "pre-wrap",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["white-space"],
  },
  "word-break": {
    isInherited: true,
    values: [
      "break-all",
      "break-word",
      "inherit",
      "initial",
      "keep-all",
      "normal",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["word-break"],
  },
  "writing-mode": {
    isInherited: true,
    values: [
      "horizontal-tb",
      "inherit",
      "initial",
      "lr",
      "lr-tb",
      "revert",
      "rl",
      "rl-tb",
      "sideways-lr",
      "sideways-rl",
      "tb",
      "tb-rl",
      "unset",
      "vertical-lr",
      "vertical-rl",
    ],
    supports: [],
    subproperties: ["writing-mode"],
  },
  "z-index": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["z-index"],
  },
  "break-after": {
    isInherited: false,
    values: [
      "always",
      "auto",
      "avoid",
      "inherit",
      "initial",
      "left",
      "page",
      "revert",
      "right",
      "unset",
    ],
    supports: [],
    subproperties: ["break-after"],
  },
  "break-before": {
    isInherited: false,
    values: [
      "always",
      "auto",
      "avoid",
      "inherit",
      "initial",
      "left",
      "page",
      "revert",
      "right",
      "unset",
    ],
    supports: [],
    subproperties: ["break-before"],
  },
  "clip-rule": {
    isInherited: true,
    values: ["evenodd", "inherit", "initial", "nonzero", "revert", "unset"],
    supports: [],
    subproperties: ["clip-rule"],
  },
  "fill-rule": {
    isInherited: true,
    values: ["evenodd", "inherit", "initial", "nonzero", "revert", "unset"],
    supports: [],
    subproperties: ["fill-rule"],
  },
  "fill-opacity": {
    isInherited: true,
    values: [
      "context-fill-opacity",
      "context-stroke-opacity",
      "inherit",
      "initial",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["fill-opacity"],
  },
  "stroke-opacity": {
    isInherited: true,
    values: [
      "context-fill-opacity",
      "context-stroke-opacity",
      "inherit",
      "initial",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["stroke-opacity"],
  },
  "-moz-box-ordinal-group": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-box-ordinal-group"],
  },
  order: {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["order"],
  },
  "flex-grow": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["flex-grow"],
  },
  "flex-shrink": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["flex-shrink"],
  },
  "-moz-box-flex": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-box-flex"],
  },
  "stroke-miterlimit": {
    isInherited: true,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["stroke-miterlimit"],
  },
  "overflow-block": {
    isInherited: false,
    values: [
      "-moz-hidden-unscrollable",
      "auto",
      "hidden",
      "inherit",
      "initial",
      "revert",
      "scroll",
      "unset",
      "visible",
    ],
    supports: [],
    subproperties: ["overflow-block"],
  },
  "overflow-inline": {
    isInherited: false,
    values: [
      "-moz-hidden-unscrollable",
      "auto",
      "hidden",
      "inherit",
      "initial",
      "revert",
      "scroll",
      "unset",
      "visible",
    ],
    supports: [],
    subproperties: ["overflow-inline"],
  },
  "overflow-x": {
    isInherited: false,
    values: [
      "-moz-hidden-unscrollable",
      "auto",
      "hidden",
      "inherit",
      "initial",
      "revert",
      "scroll",
      "unset",
      "visible",
    ],
    supports: [],
    subproperties: ["overflow-x"],
  },
  "overflow-y": {
    isInherited: false,
    values: [
      "-moz-hidden-unscrollable",
      "auto",
      "hidden",
      "inherit",
      "initial",
      "revert",
      "scroll",
      "unset",
      "visible",
    ],
    supports: [],
    subproperties: ["overflow-y"],
  },
  "overscroll-behavior-block": {
    isInherited: false,
    values: ["auto", "contain", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["overscroll-behavior-block"],
  },
  "overscroll-behavior-inline": {
    isInherited: false,
    values: ["auto", "contain", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["overscroll-behavior-inline"],
  },
  "overscroll-behavior-x": {
    isInherited: false,
    values: ["auto", "contain", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["overscroll-behavior-x"],
  },
  "overscroll-behavior-y": {
    isInherited: false,
    values: ["auto", "contain", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["overscroll-behavior-y"],
  },
  "flood-opacity": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["flood-opacity"],
  },
  opacity: {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["opacity"],
  },
  "shape-image-threshold": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["shape-image-threshold"],
  },
  "stop-opacity": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["stop-opacity"],
  },
  "border-block-end-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-block-end-style"],
  },
  "border-block-start-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-block-start-style"],
  },
  "border-bottom-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-bottom-style"],
  },
  "border-inline-end-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-inline-end-style"],
  },
  "border-inline-start-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-inline-start-style"],
  },
  "border-left-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-left-style"],
  },
  "border-right-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-right-style"],
  },
  "border-top-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-top-style"],
  },
  "column-rule-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["column-rule-style"],
  },
  "animation-delay": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["animation-delay"],
  },
  "animation-direction": {
    isInherited: false,
    values: [
      "alternate",
      "alternate-reverse",
      "inherit",
      "initial",
      "normal",
      "reverse",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["animation-direction"],
  },
  "animation-duration": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["animation-duration"],
  },
  "animation-fill-mode": {
    isInherited: false,
    values: ["backwards", "both", "forwards", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["animation-fill-mode"],
  },
  "animation-iteration-count": {
    isInherited: false,
    values: ["infinite", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["animation-iteration-count"],
  },
  "animation-name": {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["animation-name"],
  },
  "animation-play-state": {
    isInherited: false,
    values: ["inherit", "initial", "paused", "revert", "running", "unset"],
    supports: [],
    subproperties: ["animation-play-state"],
  },
  "animation-timing-function": {
    isInherited: false,
    values: [
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "inherit",
      "initial",
      "linear",
      "revert",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: ["animation-timing-function"],
  },
  "background-attachment": {
    isInherited: false,
    values: ["fixed", "inherit", "initial", "local", "revert", "scroll", "unset"],
    supports: [],
    subproperties: ["background-attachment"],
  },
  "background-blend-mode": {
    isInherited: false,
    values: [
      "color",
      "color-burn",
      "color-dodge",
      "darken",
      "difference",
      "exclusion",
      "hard-light",
      "hue",
      "inherit",
      "initial",
      "lighten",
      "luminosity",
      "multiply",
      "normal",
      "overlay",
      "revert",
      "saturation",
      "screen",
      "soft-light",
      "unset",
    ],
    supports: [],
    subproperties: ["background-blend-mode"],
  },
  "background-clip": {
    isInherited: false,
    values: [
      "border-box",
      "content-box",
      "inherit",
      "initial",
      "padding-box",
      "revert",
      "text",
      "unset",
    ],
    supports: [],
    subproperties: ["background-clip"],
  },
  "background-image": {
    isInherited: false,
    values: [
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "inherit",
      "initial",
      "linear-gradient",
      "none",
      "radial-gradient",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "unset",
      "url",
    ],
    supports: ["gradient"],
    subproperties: ["background-image"],
  },
  "background-origin": {
    isInherited: false,
    values: ["border-box", "content-box", "inherit", "initial", "padding-box", "revert", "unset"],
    supports: [],
    subproperties: ["background-origin"],
  },
  "background-position-x": {
    isInherited: false,
    values: ["center", "inherit", "initial", "left", "revert", "right", "unset"],
    supports: [],
    subproperties: ["background-position-x"],
  },
  "background-position-y": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "revert", "top", "unset"],
    supports: [],
    subproperties: ["background-position-y"],
  },
  "background-repeat": {
    isInherited: false,
    values: [
      "inherit",
      "initial",
      "no-repeat",
      "repeat",
      "repeat-x",
      "repeat-y",
      "revert",
      "round",
      "space",
      "unset",
    ],
    supports: [],
    subproperties: ["background-repeat"],
  },
  "background-size": {
    isInherited: false,
    values: ["auto", "contain", "cover", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["background-size"],
  },
  "border-image-outset": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-image-outset"],
  },
  "border-image-slice": {
    isInherited: false,
    values: ["fill", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-image-slice"],
  },
  "border-image-source": {
    isInherited: false,
    values: [
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "inherit",
      "initial",
      "linear-gradient",
      "none",
      "radial-gradient",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "unset",
      "url",
    ],
    supports: ["gradient"],
    subproperties: ["border-image-source"],
  },
  "border-image-width": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-image-width"],
  },
  "border-spacing": {
    isInherited: true,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-spacing"],
  },
  "box-shadow": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "none",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["box-shadow"],
  },
  "caret-color": {
    isInherited: true,
    values: [
      "COLOR",
      "auto",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["caret-color"],
  },
  "clip-path": {
    isInherited: false,
    values: [
      "border-box",
      "circle",
      "content-box",
      "ellipse",
      "fill-box",
      "inherit",
      "initial",
      "inset",
      "margin-box",
      "none",
      "padding-box",
      "path",
      "polygon",
      "revert",
      "stroke-box",
      "unset",
      "url",
      "view-box",
    ],
    supports: [],
    subproperties: ["clip-path"],
  },
  color: {
    isInherited: true,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["color"],
  },
  "column-width": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["column-width"],
  },
  content: {
    isInherited: false,
    values: [
      "-moz-alt-content",
      "attr",
      "close-quote",
      "counter",
      "counters",
      "inherit",
      "initial",
      "no-close-quote",
      "no-open-quote",
      "none",
      "normal",
      "open-quote",
      "revert",
      "unset",
      "url",
    ],
    supports: [],
    subproperties: ["content"],
  },
  "counter-increment": {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["counter-increment"],
  },
  cursor: {
    isInherited: true,
    values: [
      "-moz-grab",
      "-moz-grabbing",
      "-moz-zoom-in",
      "-moz-zoom-out",
      "alias",
      "all-scroll",
      "auto",
      "cell",
      "col-resize",
      "context-menu",
      "copy",
      "crosshair",
      "default",
      "e-resize",
      "ew-resize",
      "grab",
      "grabbing",
      "help",
      "inherit",
      "initial",
      "move",
      "n-resize",
      "ne-resize",
      "nesw-resize",
      "no-drop",
      "none",
      "not-allowed",
      "ns-resize",
      "nw-resize",
      "nwse-resize",
      "pointer",
      "progress",
      "revert",
      "row-resize",
      "s-resize",
      "se-resize",
      "sw-resize",
      "text",
      "unset",
      "url",
      "vertical-text",
      "w-resize",
      "wait",
      "zoom-in",
      "zoom-out",
    ],
    supports: [],
    subproperties: ["cursor"],
  },
  filter: {
    isInherited: false,
    values: [
      "blur",
      "brightness",
      "contrast",
      "drop-shadow",
      "grayscale",
      "hue-rotate",
      "inherit",
      "initial",
      "invert",
      "none",
      "opacity",
      "revert",
      "saturate",
      "sepia",
      "unset",
      "url",
    ],
    supports: [],
    subproperties: ["filter"],
  },
  "flex-basis": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["flex-basis"],
  },
  "font-family": {
    isInherited: true,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["font-family"],
  },
  "font-feature-settings": {
    isInherited: true,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["font-feature-settings"],
  },
  "font-language-override": {
    isInherited: true,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["font-language-override"],
  },
  "font-size": {
    isInherited: true,
    values: [
      "inherit",
      "initial",
      "large",
      "larger",
      "medium",
      "revert",
      "small",
      "smaller",
      "unset",
      "x-large",
      "x-small",
      "xx-large",
      "xx-small",
      "xxx-large",
    ],
    supports: [],
    subproperties: ["font-size"],
  },
  "font-variant-alternates": {
    isInherited: true,
    values: [
      "annotation",
      "character-variant",
      "historical-forms",
      "inherit",
      "initial",
      "normal",
      "ornaments",
      "revert",
      "styleset",
      "stylistic",
      "swash",
      "unset",
    ],
    supports: [],
    subproperties: ["font-variant-alternates"],
  },
  "font-variation-settings": {
    isInherited: true,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["font-variation-settings"],
  },
  "grid-template-areas": {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["grid-template-areas"],
  },
  "letter-spacing": {
    isInherited: true,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["letter-spacing"],
  },
  "line-height": {
    isInherited: true,
    values: ["-moz-block-height", "inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["line-height"],
  },
  "list-style-image": {
    isInherited: true,
    values: ["inherit", "initial", "none", "revert", "unset", "url"],
    supports: [],
    subproperties: ["list-style-image"],
  },
  "list-style-type": {
    isInherited: true,
    values: [
      "arabic-indic",
      "armenian",
      "bengali",
      "cambodian",
      "circle",
      "cjk-decimal",
      "cjk-earthly-branch",
      "cjk-heavenly-stem",
      "cjk-ideographic",
      "decimal",
      "decimal-leading-zero",
      "devanagari",
      "disc",
      "disclosure-closed",
      "disclosure-open",
      "ethiopic-numeric",
      "georgian",
      "gujarati",
      "gurmukhi",
      "hebrew",
      "hiragana",
      "hiragana-iroha",
      "inherit",
      "initial",
      "japanese-formal",
      "japanese-informal",
      "kannada",
      "katakana",
      "katakana-iroha",
      "khmer",
      "korean-hangul-formal",
      "korean-hanja-formal",
      "korean-hanja-informal",
      "lao",
      "lower-alpha",
      "lower-armenian",
      "lower-greek",
      "lower-latin",
      "lower-roman",
      "malayalam",
      "mongolian",
      "myanmar",
      "none",
      "oriya",
      "persian",
      "revert",
      "simp-chinese-formal",
      "simp-chinese-informal",
      "square",
      "symbols",
      "tamil",
      "telugu",
      "thai",
      "tibetan",
      "trad-chinese-formal",
      "trad-chinese-informal",
      "unset",
      "upper-alpha",
      "upper-armenian",
      "upper-latin",
      "upper-roman",
    ],
    supports: [],
    subproperties: ["list-style-type"],
  },
  "mask-clip": {
    isInherited: false,
    values: [
      "border-box",
      "content-box",
      "fill-box",
      "inherit",
      "initial",
      "no-clip",
      "padding-box",
      "revert",
      "stroke-box",
      "unset",
      "view-box",
    ],
    supports: [],
    subproperties: ["mask-clip"],
  },
  "mask-composite": {
    isInherited: false,
    values: ["add", "exclude", "inherit", "initial", "intersect", "revert", "subtract", "unset"],
    supports: [],
    subproperties: ["mask-composite"],
  },
  "mask-image": {
    isInherited: false,
    values: [
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "inherit",
      "initial",
      "linear-gradient",
      "none",
      "radial-gradient",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "unset",
      "url",
    ],
    supports: ["gradient"],
    subproperties: ["mask-image"],
  },
  "mask-mode": {
    isInherited: false,
    values: ["alpha", "inherit", "initial", "luminance", "match-source", "revert", "unset"],
    supports: [],
    subproperties: ["mask-mode"],
  },
  "mask-origin": {
    isInherited: false,
    values: [
      "border-box",
      "content-box",
      "fill-box",
      "inherit",
      "initial",
      "padding-box",
      "revert",
      "stroke-box",
      "unset",
      "view-box",
    ],
    supports: [],
    subproperties: ["mask-origin"],
  },
  "mask-position-x": {
    isInherited: false,
    values: ["center", "inherit", "initial", "left", "revert", "right", "unset"],
    supports: [],
    subproperties: ["mask-position-x"],
  },
  "mask-position-y": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "revert", "top", "unset"],
    supports: [],
    subproperties: ["mask-position-y"],
  },
  "mask-repeat": {
    isInherited: false,
    values: [
      "inherit",
      "initial",
      "no-repeat",
      "repeat",
      "repeat-x",
      "repeat-y",
      "revert",
      "round",
      "space",
      "unset",
    ],
    supports: [],
    subproperties: ["mask-repeat"],
  },
  "mask-size": {
    isInherited: false,
    values: ["auto", "contain", "cover", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["mask-size"],
  },
  "-moz-context-properties": {
    isInherited: true,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-context-properties"],
  },
  "-moz-tab-size": {
    isInherited: true,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-tab-size"],
  },
  "offset-anchor": {
    isInherited: false,
    values: [
      "auto",
      "bottom",
      "center",
      "inherit",
      "initial",
      "left",
      "revert",
      "right",
      "top",
      "unset",
    ],
    supports: [],
    subproperties: ["offset-anchor"],
  },
  "offset-path": {
    isInherited: false,
    values: ["inherit", "initial", "none", "path", "ray", "revert", "unset"],
    supports: [],
    subproperties: ["offset-path"],
  },
  perspective: {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["perspective"],
  },
  quotes: {
    isInherited: true,
    values: ["auto", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["quotes"],
  },
  rotate: {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["rotate"],
  },
  scale: {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["scale"],
  },
  "scrollbar-color": {
    isInherited: true,
    values: [
      "COLOR",
      "auto",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["scrollbar-color"],
  },
  "shape-outside": {
    isInherited: false,
    values: [
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "border-box",
      "circle",
      "content-box",
      "ellipse",
      "inherit",
      "initial",
      "inset",
      "linear-gradient",
      "margin-box",
      "none",
      "padding-box",
      "polygon",
      "radial-gradient",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "unset",
      "url",
    ],
    supports: ["gradient"],
    subproperties: ["shape-outside"],
  },
  "stroke-dasharray": {
    isInherited: true,
    values: ["context-value", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["stroke-dasharray"],
  },
  "stroke-dashoffset": {
    isInherited: true,
    values: ["context-value", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["stroke-dashoffset"],
  },
  "stroke-width": {
    isInherited: true,
    values: ["context-value", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["stroke-width"],
  },
  "text-decoration-thickness": {
    isInherited: false,
    values: ["auto", "from-font", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["text-decoration-thickness"],
  },
  "text-emphasis-style": {
    isInherited: true,
    values: [
      "circle",
      "dot",
      "double-circle",
      "filled",
      "inherit",
      "initial",
      "none",
      "open",
      "revert",
      "sesame",
      "triangle",
      "unset",
    ],
    supports: [],
    subproperties: ["text-emphasis-style"],
  },
  "text-overflow": {
    isInherited: false,
    values: ["clip", "ellipsis", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["text-overflow"],
  },
  "text-shadow": {
    isInherited: true,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "none",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["text-shadow"],
  },
  "transition-delay": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["transition-delay"],
  },
  "transition-duration": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["transition-duration"],
  },
  "transition-property": {
    isInherited: false,
    values: ["all", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["transition-property"],
  },
  "transition-timing-function": {
    isInherited: false,
    values: [
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "inherit",
      "initial",
      "linear",
      "revert",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: ["transition-timing-function"],
  },
  translate: {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["translate"],
  },
  "vertical-align": {
    isInherited: false,
    values: [
      "-moz-middle-with-baseline",
      "baseline",
      "bottom",
      "inherit",
      "initial",
      "middle",
      "revert",
      "sub",
      "super",
      "text-bottom",
      "text-top",
      "top",
      "unset",
    ],
    supports: [],
    subproperties: ["vertical-align"],
  },
  "will-change": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["will-change"],
  },
  "word-spacing": {
    isInherited: true,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["word-spacing"],
  },
  clip: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "rect", "revert", "unset"],
    supports: [],
    subproperties: ["clip"],
  },
  "-moz-image-region": {
    isInherited: true,
    values: ["auto", "inherit", "initial", "rect", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-image-region"],
  },
  "object-position": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "left", "revert", "right", "top", "unset"],
    supports: [],
    subproperties: ["object-position"],
  },
  "perspective-origin": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "left", "revert", "right", "top", "unset"],
    supports: [],
    subproperties: ["perspective-origin"],
  },
  fill: {
    isInherited: true,
    values: [
      "COLOR",
      "context-fill",
      "context-stroke",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "none",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
      "url",
    ],
    supports: ["color"],
    subproperties: ["fill"],
  },
  stroke: {
    isInherited: true,
    values: [
      "COLOR",
      "context-fill",
      "context-stroke",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "none",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
      "url",
    ],
    supports: ["color"],
    subproperties: ["stroke"],
  },
  "transform-origin": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "left", "revert", "right", "top", "unset"],
    supports: [],
    subproperties: ["transform-origin"],
  },
  "counter-reset": {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["counter-reset"],
  },
  "counter-set": {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["counter-set"],
  },
  "grid-template-columns": {
    isInherited: false,
    values: [
      "auto",
      "fit-content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "minmax",
      "none",
      "repeat",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["grid-template-columns"],
  },
  "grid-template-rows": {
    isInherited: false,
    values: [
      "auto",
      "fit-content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "minmax",
      "none",
      "repeat",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["grid-template-rows"],
  },
  "grid-auto-columns": {
    isInherited: false,
    values: [
      "auto",
      "fit-content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "minmax",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["grid-auto-columns"],
  },
  "grid-auto-rows": {
    isInherited: false,
    values: [
      "auto",
      "fit-content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "minmax",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["grid-auto-rows"],
  },
  transform: {
    isInherited: false,
    values: [
      "accumulatematrix",
      "inherit",
      "initial",
      "interpolatematrix",
      "matrix",
      "matrix3d",
      "none",
      "perspective",
      "revert",
      "rotate",
      "rotate3d",
      "rotateX",
      "rotateY",
      "rotateZ",
      "scale",
      "scale3d",
      "scaleX",
      "scaleY",
      "scaleZ",
      "skew",
      "skewX",
      "skewY",
      "translate",
      "translate3d",
      "translateX",
      "translateY",
      "translateZ",
      "unset",
    ],
    supports: [],
    subproperties: ["transform"],
  },
  "column-gap": {
    isInherited: false,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["column-gap"],
  },
  "row-gap": {
    isInherited: false,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["row-gap"],
  },
  "marker-end": {
    isInherited: true,
    values: ["inherit", "initial", "none", "revert", "unset", "url"],
    supports: [],
    subproperties: ["marker-end"],
  },
  "marker-mid": {
    isInherited: true,
    values: ["inherit", "initial", "none", "revert", "unset", "url"],
    supports: [],
    subproperties: ["marker-mid"],
  },
  "marker-start": {
    isInherited: true,
    values: ["inherit", "initial", "none", "revert", "unset", "url"],
    supports: [],
    subproperties: ["marker-start"],
  },
  "grid-column-end": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["grid-column-end"],
  },
  "grid-column-start": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["grid-column-start"],
  },
  "grid-row-end": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["grid-row-end"],
  },
  "grid-row-start": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["grid-row-start"],
  },
  "max-block-size": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "none",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["max-block-size"],
  },
  "max-height": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "none",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["max-height"],
  },
  "max-inline-size": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "none",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["max-inline-size"],
  },
  "max-width": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "none",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["max-width"],
  },
  cx: {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["cx"],
  },
  cy: {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["cy"],
  },
  "offset-distance": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["offset-distance"],
  },
  "text-indent": {
    isInherited: true,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["text-indent"],
  },
  x: {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["x"],
  },
  y: {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["y"],
  },
  "block-size": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["block-size"],
  },
  height: {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["height"],
  },
  "inline-size": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["inline-size"],
  },
  "min-block-size": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["min-block-size"],
  },
  "min-height": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["min-height"],
  },
  "min-inline-size": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["min-inline-size"],
  },
  "min-width": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["min-width"],
  },
  width: {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["width"],
  },
  "outline-offset": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["outline-offset"],
  },
  "scroll-margin-block-end": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-margin-block-end"],
  },
  "scroll-margin-block-start": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-margin-block-start"],
  },
  "scroll-margin-bottom": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-margin-bottom"],
  },
  "scroll-margin-inline-end": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-margin-inline-end"],
  },
  "scroll-margin-inline-start": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-margin-inline-start"],
  },
  "scroll-margin-left": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-margin-left"],
  },
  "scroll-margin-right": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-margin-right"],
  },
  "scroll-margin-top": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-margin-top"],
  },
  "padding-block-end": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-block-end"],
  },
  "padding-block-start": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-block-start"],
  },
  "padding-bottom": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-bottom"],
  },
  "padding-inline-end": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-inline-end"],
  },
  "padding-inline-start": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-inline-start"],
  },
  "padding-left": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-left"],
  },
  "padding-right": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-right"],
  },
  "padding-top": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-top"],
  },
  r: {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["r"],
  },
  "shape-margin": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["shape-margin"],
  },
  rx: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["rx"],
  },
  ry: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["ry"],
  },
  "scroll-padding-block-end": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-padding-block-end"],
  },
  "scroll-padding-block-start": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-padding-block-start"],
  },
  "scroll-padding-bottom": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-padding-bottom"],
  },
  "scroll-padding-inline-end": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-padding-inline-end"],
  },
  "scroll-padding-inline-start": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-padding-inline-start"],
  },
  "scroll-padding-left": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-padding-left"],
  },
  "scroll-padding-right": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-padding-right"],
  },
  "scroll-padding-top": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-padding-top"],
  },
  "border-block-end-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-block-end-width"],
  },
  "border-block-start-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-block-start-width"],
  },
  "border-bottom-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-bottom-width"],
  },
  "border-inline-end-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-inline-end-width"],
  },
  "border-inline-start-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-inline-start-width"],
  },
  "border-left-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-left-width"],
  },
  "border-right-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-right-width"],
  },
  "border-top-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-top-width"],
  },
  "column-rule-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["column-rule-width"],
  },
  "outline-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["outline-width"],
  },
  "-webkit-text-stroke-width": {
    isInherited: true,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["-webkit-text-stroke-width"],
  },
  "border-bottom-left-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-bottom-left-radius"],
  },
  "border-bottom-right-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-bottom-right-radius"],
  },
  "border-end-end-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-end-end-radius"],
  },
  "border-end-start-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-end-start-radius"],
  },
  "border-start-end-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-start-end-radius"],
  },
  "border-start-start-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-start-start-radius"],
  },
  "border-top-left-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-top-left-radius"],
  },
  "border-top-right-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-top-right-radius"],
  },
  "-moz-outline-radius-bottomleft": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-outline-radius-bottomleft"],
  },
  "-moz-outline-radius-bottomright": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-outline-radius-bottomright"],
  },
  "-moz-outline-radius-topleft": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-outline-radius-topleft"],
  },
  "-moz-outline-radius-topright": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-outline-radius-topright"],
  },
  bottom: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["bottom"],
  },
  "inset-block-end": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["inset-block-end"],
  },
  "inset-block-start": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["inset-block-start"],
  },
  "inset-inline-end": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["inset-inline-end"],
  },
  "inset-inline-start": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["inset-inline-start"],
  },
  left: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["left"],
  },
  "margin-block-end": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-block-end"],
  },
  "margin-block-start": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-block-start"],
  },
  "margin-bottom": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-bottom"],
  },
  "margin-inline-end": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-inline-end"],
  },
  "margin-inline-start": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-inline-start"],
  },
  "margin-left": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-left"],
  },
  "margin-right": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-right"],
  },
  "margin-top": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-top"],
  },
  right: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["right"],
  },
  "text-underline-offset": {
    isInherited: true,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["text-underline-offset"],
  },
  top: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["top"],
  },
  "background-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["background-color"],
  },
  "border-block-end-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-block-end-color"],
  },
  "border-block-start-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-block-start-color"],
  },
  "border-bottom-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-bottom-color"],
  },
  "border-inline-end-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-inline-end-color"],
  },
  "border-inline-start-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-inline-start-color"],
  },
  "border-left-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-left-color"],
  },
  "border-right-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-right-color"],
  },
  "border-top-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-top-color"],
  },
  "column-rule-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["column-rule-color"],
  },
  "flood-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["flood-color"],
  },
  "lighting-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["lighting-color"],
  },
  "outline-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["outline-color"],
  },
  "stop-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["stop-color"],
  },
  "text-decoration-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["text-decoration-color"],
  },
  "text-emphasis-color": {
    isInherited: true,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["text-emphasis-color"],
  },
  "-webkit-text-fill-color": {
    isInherited: true,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["-webkit-text-fill-color"],
  },
  "-webkit-text-stroke-color": {
    isInherited: true,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["-webkit-text-stroke-color"],
  },
  background: {
    isInherited: false,
    values: [
      "COLOR",
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "auto",
      "border-box",
      "bottom",
      "center",
      "contain",
      "content-box",
      "cover",
      "currentColor",
      "fixed",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "left",
      "linear-gradient",
      "local",
      "no-repeat",
      "none",
      "padding-box",
      "radial-gradient",
      "repeat",
      "repeat-x",
      "repeat-y",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "rgb",
      "rgba",
      "right",
      "round",
      "scroll",
      "space",
      "text",
      "top",
      "transparent",
      "unset",
      "url",
    ],
    supports: ["color", "gradient"],
    subproperties: [
      "background-color",
      "background-position-x",
      "background-position-y",
      "background-repeat",
      "background-attachment",
      "background-image",
      "background-size",
      "background-origin",
      "background-clip",
    ],
  },
  "background-position": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "left", "revert", "right", "top", "unset"],
    supports: [],
    subproperties: ["background-position-x", "background-position-y"],
  },
  "border-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: [
      "border-top-color",
      "border-right-color",
      "border-bottom-color",
      "border-left-color",
    ],
  },
  "border-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: [
      "border-top-style",
      "border-right-style",
      "border-bottom-style",
      "border-left-style",
    ],
  },
  "border-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: [
      "border-top-width",
      "border-right-width",
      "border-bottom-width",
      "border-left-width",
    ],
  },
  "border-top": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-top-color", "border-top-style", "border-top-width"],
  },
  "border-right": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-right-color", "border-right-style", "border-right-width"],
  },
  "border-bottom": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-bottom-color", "border-bottom-style", "border-bottom-width"],
  },
  "border-left": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-left-color", "border-left-style", "border-left-width"],
  },
  "border-block-start": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: [
      "border-block-start-color",
      "border-block-start-style",
      "border-block-start-width",
    ],
  },
  "border-block-end": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-block-end-color", "border-block-end-style", "border-block-end-width"],
  },
  "border-inline-start": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: [
      "border-inline-start-color",
      "border-inline-start-style",
      "border-inline-start-width",
    ],
  },
  "border-inline-end": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: [
      "border-inline-end-color",
      "border-inline-end-style",
      "border-inline-end-width",
    ],
  },
  border: {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: [
      "border-top-color",
      "border-top-style",
      "border-top-width",
      "border-right-color",
      "border-right-style",
      "border-right-width",
      "border-bottom-color",
      "border-bottom-style",
      "border-bottom-width",
      "border-left-color",
      "border-left-style",
      "border-left-width",
      "border-image-outset",
      "border-image-repeat",
      "border-image-slice",
      "border-image-source",
      "border-image-width",
    ],
  },
  "border-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: [
      "border-top-left-radius",
      "border-top-right-radius",
      "border-bottom-right-radius",
      "border-bottom-left-radius",
    ],
  },
  "border-image": {
    isInherited: false,
    values: [
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "auto",
      "fill",
      "inherit",
      "initial",
      "linear-gradient",
      "none",
      "radial-gradient",
      "repeat",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "round",
      "space",
      "stretch",
      "unset",
      "url",
    ],
    supports: ["gradient"],
    subproperties: [
      "border-image-outset",
      "border-image-repeat",
      "border-image-slice",
      "border-image-source",
      "border-image-width",
    ],
  },
  "border-block-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-block-start-width", "border-block-end-width"],
  },
  "border-block-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-block-start-style", "border-block-end-style"],
  },
  "border-block-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-block-start-color", "border-block-end-color"],
  },
  "border-inline-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-inline-start-width", "border-inline-end-width"],
  },
  "border-inline-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-inline-start-style", "border-inline-end-style"],
  },
  "border-inline-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-inline-start-color", "border-inline-end-color"],
  },
  "border-block": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: [
      "border-block-start-width",
      "border-block-end-width",
      "border-block-start-style",
      "border-block-end-style",
      "border-block-start-color",
      "border-block-end-color",
    ],
  },
  "border-inline": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: [
      "border-inline-start-width",
      "border-inline-end-width",
      "border-inline-start-style",
      "border-inline-end-style",
      "border-inline-start-color",
      "border-inline-end-color",
    ],
  },
  overflow: {
    isInherited: false,
    values: [
      "-moz-hidden-unscrollable",
      "auto",
      "hidden",
      "inherit",
      "initial",
      "revert",
      "scroll",
      "unset",
      "visible",
    ],
    supports: [],
    subproperties: ["overflow-x", "overflow-y"],
  },
  transition: {
    isInherited: false,
    values: [
      "all",
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "inherit",
      "initial",
      "linear",
      "none",
      "revert",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: [
      "transition-property",
      "transition-duration",
      "transition-timing-function",
      "transition-delay",
    ],
  },
  animation: {
    isInherited: false,
    values: [
      "alternate",
      "alternate-reverse",
      "backwards",
      "both",
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "forwards",
      "infinite",
      "inherit",
      "initial",
      "linear",
      "none",
      "normal",
      "paused",
      "reverse",
      "revert",
      "running",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: [
      "animation-name",
      "animation-duration",
      "animation-timing-function",
      "animation-delay",
      "animation-iteration-count",
      "animation-direction",
      "animation-fill-mode",
      "animation-play-state",
    ],
  },
  "overscroll-behavior": {
    isInherited: false,
    values: ["auto", "contain", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["overscroll-behavior-x", "overscroll-behavior-y"],
  },
  "page-break-before": {
    isInherited: false,
    values: [
      "always",
      "auto",
      "avoid",
      "inherit",
      "initial",
      "left",
      "page",
      "revert",
      "right",
      "unset",
    ],
    supports: [],
    subproperties: ["break-before"],
  },
  "page-break-after": {
    isInherited: false,
    values: [
      "always",
      "auto",
      "avoid",
      "inherit",
      "initial",
      "left",
      "page",
      "revert",
      "right",
      "unset",
    ],
    supports: [],
    subproperties: ["break-after"],
  },
  offset: {
    isInherited: false,
    values: [
      "auto",
      "bottom",
      "center",
      "inherit",
      "initial",
      "left",
      "none",
      "path",
      "ray",
      "reverse",
      "revert",
      "right",
      "top",
      "unset",
    ],
    supports: [],
    subproperties: ["offset-path", "offset-distance", "offset-rotate", "offset-anchor"],
  },
  columns: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["column-width", "column-count"],
  },
  "column-rule": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["column-rule-width", "column-rule-style", "column-rule-color"],
  },
  font: {
    isInherited: true,
    values: [
      "-moz-button",
      "-moz-desktop",
      "-moz-dialog",
      "-moz-document",
      "-moz-field",
      "-moz-info",
      "-moz-list",
      "-moz-pull-down-menu",
      "-moz-window",
      "-moz-workspace",
      "all-petite-caps",
      "all-small-caps",
      "bold",
      "bolder",
      "caption",
      "condensed",
      "expanded",
      "extra-condensed",
      "extra-expanded",
      "icon",
      "inherit",
      "initial",
      "italic",
      "large",
      "larger",
      "lighter",
      "medium",
      "menu",
      "message-box",
      "normal",
      "oblique",
      "petite-caps",
      "revert",
      "semi-condensed",
      "semi-expanded",
      "small",
      "small-caps",
      "small-caption",
      "smaller",
      "status-bar",
      "titling-caps",
      "ultra-condensed",
      "ultra-expanded",
      "unicase",
      "unset",
      "x-large",
      "x-small",
      "xx-large",
      "xx-small",
      "xxx-large",
    ],
    supports: [],
    subproperties: [
      "font-style",
      "font-variant-caps",
      "font-weight",
      "font-stretch",
      "font-size",
      "line-height",
      "font-family",
      "font-size-adjust",
      "font-kerning",
      "font-optical-sizing",
      "font-variant-alternates",
      "font-variant-east-asian",
      "font-variant-ligatures",
      "font-variant-numeric",
      "font-variant-position",
      "font-language-override",
      "font-feature-settings",
      "font-variation-settings",
    ],
  },
  "font-variant": {
    isInherited: true,
    values: [
      "all-petite-caps",
      "all-small-caps",
      "annotation",
      "character-variant",
      "common-ligatures",
      "contextual",
      "diagonal-fractions",
      "discretionary-ligatures",
      "full-width",
      "historical-forms",
      "historical-ligatures",
      "inherit",
      "initial",
      "jis04",
      "jis78",
      "jis83",
      "jis90",
      "lining-nums",
      "no-common-ligatures",
      "no-contextual",
      "no-discretionary-ligatures",
      "no-historical-ligatures",
      "none",
      "normal",
      "oldstyle-nums",
      "ordinal",
      "ornaments",
      "petite-caps",
      "proportional-nums",
      "proportional-width",
      "revert",
      "ruby",
      "simplified",
      "slashed-zero",
      "small-caps",
      "stacked-fractions",
      "styleset",
      "stylistic",
      "sub",
      "super",
      "swash",
      "tabular-nums",
      "titling-caps",
      "traditional",
      "unicase",
      "unset",
    ],
    supports: [],
    subproperties: [
      "font-variant-caps",
      "font-variant-alternates",
      "font-variant-east-asian",
      "font-variant-ligatures",
      "font-variant-numeric",
      "font-variant-position",
    ],
  },
  marker: {
    isInherited: true,
    values: ["inherit", "initial", "none", "revert", "unset", "url"],
    supports: [],
    subproperties: ["marker-start", "marker-end", "marker-mid"],
  },
  "text-emphasis": {
    isInherited: true,
    values: [
      "COLOR",
      "circle",
      "currentColor",
      "dot",
      "double-circle",
      "filled",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "none",
      "open",
      "revert",
      "rgb",
      "rgba",
      "sesame",
      "transparent",
      "triangle",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["text-emphasis-style", "text-emphasis-color"],
  },
  "-webkit-text-stroke": {
    isInherited: true,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "medium",
      "revert",
      "rgb",
      "rgba",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["-webkit-text-stroke-width", "-webkit-text-stroke-color"],
  },
  "list-style": {
    isInherited: true,
    values: [
      "arabic-indic",
      "armenian",
      "bengali",
      "cambodian",
      "circle",
      "cjk-decimal",
      "cjk-earthly-branch",
      "cjk-heavenly-stem",
      "cjk-ideographic",
      "decimal",
      "decimal-leading-zero",
      "devanagari",
      "disc",
      "disclosure-closed",
      "disclosure-open",
      "ethiopic-numeric",
      "georgian",
      "gujarati",
      "gurmukhi",
      "hebrew",
      "hiragana",
      "hiragana-iroha",
      "inherit",
      "initial",
      "inside",
      "japanese-formal",
      "japanese-informal",
      "kannada",
      "katakana",
      "katakana-iroha",
      "khmer",
      "korean-hangul-formal",
      "korean-hanja-formal",
      "korean-hanja-informal",
      "lao",
      "lower-alpha",
      "lower-armenian",
      "lower-greek",
      "lower-latin",
      "lower-roman",
      "malayalam",
      "mongolian",
      "myanmar",
      "none",
      "oriya",
      "outside",
      "persian",
      "revert",
      "simp-chinese-formal",
      "simp-chinese-informal",
      "square",
      "symbols",
      "tamil",
      "telugu",
      "thai",
      "tibetan",
      "trad-chinese-formal",
      "trad-chinese-informal",
      "unset",
      "upper-alpha",
      "upper-armenian",
      "upper-latin",
      "upper-roman",
      "url",
    ],
    supports: [],
    subproperties: ["list-style-position", "list-style-image", "list-style-type"],
  },
  margin: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-top", "margin-right", "margin-bottom", "margin-left"],
  },
  "margin-block": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-block-start", "margin-block-end"],
  },
  "margin-inline": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-inline-start", "margin-inline-end"],
  },
  "scroll-margin": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: [
      "scroll-margin-top",
      "scroll-margin-right",
      "scroll-margin-bottom",
      "scroll-margin-left",
    ],
  },
  "scroll-margin-block": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-margin-block-start", "scroll-margin-block-end"],
  },
  "scroll-margin-inline": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-margin-inline-start", "scroll-margin-inline-end"],
  },
  outline: {
    isInherited: false,
    values: [
      "COLOR",
      "auto",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["outline-color", "outline-style", "outline-width"],
  },
  "-moz-outline-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: [
      "-moz-outline-radius-topleft",
      "-moz-outline-radius-topright",
      "-moz-outline-radius-bottomright",
      "-moz-outline-radius-bottomleft",
    ],
  },
  padding: {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-top", "padding-right", "padding-bottom", "padding-left"],
  },
  "padding-block": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-block-start", "padding-block-end"],
  },
  "padding-inline": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-inline-start", "padding-inline-end"],
  },
  "scroll-padding": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: [
      "scroll-padding-top",
      "scroll-padding-right",
      "scroll-padding-bottom",
      "scroll-padding-left",
    ],
  },
  "scroll-padding-block": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-padding-block-start", "scroll-padding-block-end"],
  },
  "scroll-padding-inline": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["scroll-padding-inline-start", "scroll-padding-inline-end"],
  },
  "flex-flow": {
    isInherited: false,
    values: [
      "column",
      "column-reverse",
      "inherit",
      "initial",
      "nowrap",
      "revert",
      "row",
      "row-reverse",
      "unset",
      "wrap",
      "wrap-reverse",
    ],
    supports: [],
    subproperties: ["flex-direction", "flex-wrap"],
  },
  flex: {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["flex-grow", "flex-shrink", "flex-basis"],
  },
  gap: {
    isInherited: false,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["row-gap", "column-gap"],
  },
  "grid-row": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["grid-row-start", "grid-row-end"],
  },
  "grid-column": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["grid-column-start", "grid-column-end"],
  },
  "grid-area": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["grid-row-start", "grid-row-end", "grid-column-start", "grid-column-end"],
  },
  "grid-template": {
    isInherited: false,
    values: [
      "auto",
      "fit-content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "minmax",
      "none",
      "repeat",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["grid-template-rows", "grid-template-columns", "grid-template-areas"],
  },
  grid: {
    isInherited: false,
    values: [
      "auto",
      "column",
      "dense",
      "fit-content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "minmax",
      "none",
      "repeat",
      "revert",
      "row",
      "unset",
    ],
    supports: [],
    subproperties: [
      "grid-template-rows",
      "grid-template-columns",
      "grid-template-areas",
      "grid-auto-rows",
      "grid-auto-columns",
      "grid-auto-flow",
    ],
  },
  "place-content": {
    isInherited: false,
    values: [
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "left",
      "normal",
      "revert",
      "right",
      "safe",
      "space-around",
      "space-between",
      "space-evenly",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["align-content", "justify-content"],
  },
  "place-self": {
    isInherited: false,
    values: [
      "auto",
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "left",
      "normal",
      "revert",
      "right",
      "safe",
      "self-end",
      "self-start",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["align-self", "justify-self"],
  },
  "place-items": {
    isInherited: false,
    values: [
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "left",
      "legacy",
      "normal",
      "revert",
      "right",
      "safe",
      "self-end",
      "self-start",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["align-items", "justify-items"],
  },
  inset: {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["top", "right", "bottom", "left"],
  },
  "inset-block": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["inset-block-start", "inset-block-end"],
  },
  "inset-inline": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["inset-inline-start", "inset-inline-end"],
  },
  mask: {
    isInherited: false,
    values: [
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "add",
      "alpha",
      "auto",
      "border-box",
      "bottom",
      "center",
      "contain",
      "content-box",
      "cover",
      "exclude",
      "fill-box",
      "inherit",
      "initial",
      "intersect",
      "left",
      "linear-gradient",
      "luminance",
      "match-source",
      "no-clip",
      "no-repeat",
      "none",
      "padding-box",
      "radial-gradient",
      "repeat",
      "repeat-x",
      "repeat-y",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "right",
      "round",
      "space",
      "stroke-box",
      "subtract",
      "top",
      "unset",
      "url",
      "view-box",
    ],
    supports: ["gradient"],
    subproperties: [
      "mask-mode",
      "mask-repeat",
      "mask-clip",
      "mask-origin",
      "mask-composite",
      "mask-position-x",
      "mask-position-y",
      "mask-size",
      "mask-image",
    ],
  },
  "mask-position": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "left", "revert", "right", "top", "unset"],
    supports: [],
    subproperties: ["mask-position-x", "mask-position-y"],
  },
  "text-decoration": {
    isInherited: false,
    values: [
      "COLOR",
      "-moz-none",
      "auto",
      "blink",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "from-font",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "line-through",
      "none",
      "overline",
      "revert",
      "rgb",
      "rgba",
      "solid",
      "transparent",
      "underline",
      "unset",
      "wavy",
    ],
    supports: ["color"],
    subproperties: [
      "text-decoration-line",
      "text-decoration-style",
      "text-decoration-color",
      "text-decoration-thickness",
    ],
  },
  all: {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: [
      "border-block-start-color",
      "border-block-start-style",
      "border-block-start-width",
      "border-block-end-color",
      "border-block-end-style",
      "border-block-end-width",
      "border-inline-start-color",
      "border-inline-start-style",
      "border-inline-start-width",
      "border-inline-end-color",
      "border-inline-end-style",
      "border-inline-end-width",
      "border-start-start-radius",
      "border-start-end-radius",
      "border-end-start-radius",
      "border-end-end-radius",
      "overflow-inline",
      "overflow-block",
      "overscroll-behavior-inline",
      "overscroll-behavior-block",
      "margin-block-start",
      "margin-block-end",
      "margin-inline-start",
      "margin-inline-end",
      "scroll-margin-block-start",
      "scroll-margin-block-end",
      "scroll-margin-inline-start",
      "scroll-margin-inline-end",
      "padding-block-start",
      "padding-block-end",
      "padding-inline-start",
      "padding-inline-end",
      "scroll-padding-block-start",
      "scroll-padding-block-end",
      "scroll-padding-inline-start",
      "scroll-padding-inline-end",
      "inset-block-start",
      "inset-block-end",
      "inset-inline-start",
      "inset-inline-end",
      "block-size",
      "min-block-size",
      "max-block-size",
      "inline-size",
      "min-inline-size",
      "max-inline-size",
      "background-color",
      "background-image",
      "background-position-x",
      "background-position-y",
      "background-repeat",
      "background-attachment",
      "background-clip",
      "background-origin",
      "background-size",
      "background-blend-mode",
      "border-top-color",
      "border-top-style",
      "border-top-width",
      "border-right-color",
      "border-right-style",
      "border-right-width",
      "border-bottom-color",
      "border-bottom-style",
      "border-bottom-width",
      "border-left-color",
      "border-left-style",
      "border-left-width",
      "border-top-left-radius",
      "border-top-right-radius",
      "border-bottom-right-radius",
      "border-bottom-left-radius",
      "box-decoration-break",
      "-moz-float-edge",
      "border-image-source",
      "border-image-outset",
      "border-image-repeat",
      "border-image-width",
      "border-image-slice",
      "display",
      "position",
      "float",
      "clear",
      "vertical-align",
      "overflow-clip-box-inline",
      "overflow-clip-box-block",
      "overflow-x",
      "overflow-y",
      "overflow-anchor",
      "transition-duration",
      "transition-timing-function",
      "transition-property",
      "transition-delay",
      "animation-name",
      "animation-duration",
      "animation-timing-function",
      "animation-iteration-count",
      "animation-direction",
      "animation-play-state",
      "animation-fill-mode",
      "animation-delay",
      "transform",
      "rotate",
      "scale",
      "translate",
      "offset-path",
      "offset-distance",
      "offset-rotate",
      "offset-anchor",
      "scroll-behavior",
      "scroll-snap-align",
      "scroll-snap-type",
      "overscroll-behavior-x",
      "overscroll-behavior-y",
      "isolation",
      "break-after",
      "break-before",
      "break-inside",
      "resize",
      "perspective",
      "perspective-origin",
      "backface-visibility",
      "transform-box",
      "transform-style",
      "transform-origin",
      "contain",
      "-moz-appearance",
      "-moz-orient",
      "will-change",
      "shape-image-threshold",
      "shape-margin",
      "shape-outside",
      "touch-action",
      "-webkit-line-clamp",
      "column-width",
      "column-count",
      "column-fill",
      "column-rule-width",
      "column-rule-color",
      "column-span",
      "column-rule-style",
      "content",
      "counter-increment",
      "counter-reset",
      "counter-set",
      "opacity",
      "box-shadow",
      "clip",
      "filter",
      "backdrop-filter",
      "mix-blend-mode",
      "font-family",
      "font-style",
      "font-variant-caps",
      "font-weight",
      "font-size",
      "font-size-adjust",
      "font-synthesis",
      "font-stretch",
      "font-kerning",
      "font-variant-alternates",
      "font-variant-east-asian",
      "font-variant-ligatures",
      "font-variant-numeric",
      "font-variant-position",
      "font-feature-settings",
      "font-variation-settings",
      "font-language-override",
      "font-optical-sizing",
      "-moz-osx-font-smoothing",
      "visibility",
      "writing-mode",
      "text-orientation",
      "color-adjust",
      "image-rendering",
      "image-orientation",
      "dominant-baseline",
      "text-anchor",
      "color-interpolation",
      "color-interpolation-filters",
      "fill",
      "fill-opacity",
      "fill-rule",
      "shape-rendering",
      "stroke",
      "stroke-width",
      "stroke-linecap",
      "stroke-linejoin",
      "stroke-miterlimit",
      "stroke-opacity",
      "stroke-dasharray",
      "stroke-dashoffset",
      "clip-rule",
      "marker-start",
      "marker-mid",
      "marker-end",
      "paint-order",
      "-moz-context-properties",
      "border-collapse",
      "empty-cells",
      "caption-side",
      "border-spacing",
      "color",
      "line-height",
      "text-transform",
      "hyphens",
      "-moz-text-size-adjust",
      "text-indent",
      "overflow-wrap",
      "word-break",
      "text-justify",
      "text-align-last",
      "text-align",
      "letter-spacing",
      "word-spacing",
      "white-space",
      "text-shadow",
      "text-emphasis-style",
      "text-emphasis-position",
      "text-emphasis-color",
      "-moz-tab-size",
      "line-break",
      "-webkit-text-fill-color",
      "-webkit-text-stroke-color",
      "-webkit-text-stroke-width",
      "ruby-align",
      "ruby-position",
      "text-combine-upright",
      "text-rendering",
      "-moz-control-character-visibility",
      "text-underline-offset",
      "text-underline-position",
      "text-decoration-skip-ink",
      "cursor",
      "pointer-events",
      "-moz-user-input",
      "-moz-user-modify",
      "-moz-user-focus",
      "caret-color",
      "scrollbar-color",
      "list-style-position",
      "list-style-type",
      "list-style-image",
      "quotes",
      "-moz-image-region",
      "margin-top",
      "margin-right",
      "margin-bottom",
      "margin-left",
      "scroll-margin-top",
      "scroll-margin-right",
      "scroll-margin-bottom",
      "scroll-margin-left",
      "outline-color",
      "outline-style",
      "outline-width",
      "-moz-outline-radius-topleft",
      "-moz-outline-radius-topright",
      "-moz-outline-radius-bottomright",
      "-moz-outline-radius-bottomleft",
      "outline-offset",
      "padding-top",
      "padding-right",
      "padding-bottom",
      "padding-left",
      "scroll-padding-top",
      "scroll-padding-right",
      "scroll-padding-bottom",
      "scroll-padding-left",
      "top",
      "right",
      "bottom",
      "left",
      "z-index",
      "flex-direction",
      "flex-wrap",
      "justify-content",
      "align-content",
      "align-items",
      "justify-items",
      "flex-grow",
      "flex-shrink",
      "align-self",
      "justify-self",
      "order",
      "flex-basis",
      "width",
      "min-width",
      "max-width",
      "height",
      "min-height",
      "max-height",
      "box-sizing",
      "object-fit",
      "object-position",
      "grid-row-start",
      "grid-row-end",
      "grid-auto-rows",
      "grid-template-rows",
      "grid-column-start",
      "grid-column-end",
      "grid-auto-columns",
      "grid-template-columns",
      "grid-auto-flow",
      "grid-template-areas",
      "column-gap",
      "row-gap",
      "vector-effect",
      "stop-color",
      "stop-opacity",
      "flood-color",
      "flood-opacity",
      "lighting-color",
      "mask-type",
      "clip-path",
      "mask-mode",
      "mask-repeat",
      "mask-position-x",
      "mask-position-y",
      "mask-clip",
      "mask-origin",
      "mask-size",
      "mask-composite",
      "mask-image",
      "x",
      "y",
      "cx",
      "cy",
      "rx",
      "ry",
      "r",
      "table-layout",
      "text-overflow",
      "text-decoration-line",
      "text-decoration-style",
      "text-decoration-color",
      "initial-letter",
      "text-decoration-thickness",
      "ime-mode",
      "scrollbar-width",
      "user-select",
      "-moz-window-dragging",
      "-moz-force-broken-image-icon",
      "-moz-box-align",
      "-moz-box-direction",
      "-moz-box-flex",
      "-moz-box-orient",
      "-moz-box-pack",
      "-moz-box-ordinal-group",
    ],
  },
  "-webkit-background-clip": {
    isInherited: false,
    values: [
      "border-box",
      "content-box",
      "inherit",
      "initial",
      "padding-box",
      "revert",
      "text",
      "unset",
    ],
    supports: [],
    subproperties: ["background-clip"],
  },
  "-webkit-background-origin": {
    isInherited: false,
    values: ["border-box", "content-box", "inherit", "initial", "padding-box", "revert", "unset"],
    supports: [],
    subproperties: ["background-origin"],
  },
  "-webkit-background-size": {
    isInherited: false,
    values: ["auto", "contain", "cover", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["background-size"],
  },
  "-moz-border-start-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-inline-start-color"],
  },
  "-moz-border-start-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-inline-start-style"],
  },
  "-moz-border-start-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-inline-start-width"],
  },
  "-moz-border-end-color": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["border-inline-end-color"],
  },
  "-moz-border-end-style": {
    isInherited: false,
    values: [
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inherit",
      "initial",
      "inset",
      "none",
      "outset",
      "revert",
      "ridge",
      "solid",
      "unset",
    ],
    supports: [],
    subproperties: ["border-inline-end-style"],
  },
  "-moz-border-end-width": {
    isInherited: false,
    values: ["inherit", "initial", "medium", "revert", "thick", "thin", "unset"],
    supports: [],
    subproperties: ["border-inline-end-width"],
  },
  "-webkit-border-top-left-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-top-left-radius"],
  },
  "-webkit-border-top-right-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-top-right-radius"],
  },
  "-webkit-border-bottom-right-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-bottom-right-radius"],
  },
  "-webkit-border-bottom-left-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["border-bottom-left-radius"],
  },
  "-moz-transition-duration": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["transition-duration"],
  },
  "-webkit-transition-duration": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["transition-duration"],
  },
  "-moz-transition-timing-function": {
    isInherited: false,
    values: [
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "inherit",
      "initial",
      "linear",
      "revert",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: ["transition-timing-function"],
  },
  "-webkit-transition-timing-function": {
    isInherited: false,
    values: [
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "inherit",
      "initial",
      "linear",
      "revert",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: ["transition-timing-function"],
  },
  "-moz-transition-property": {
    isInherited: false,
    values: ["all", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["transition-property"],
  },
  "-webkit-transition-property": {
    isInherited: false,
    values: ["all", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["transition-property"],
  },
  "-moz-transition-delay": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["transition-delay"],
  },
  "-webkit-transition-delay": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["transition-delay"],
  },
  "-moz-animation-name": {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["animation-name"],
  },
  "-webkit-animation-name": {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["animation-name"],
  },
  "-moz-animation-duration": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["animation-duration"],
  },
  "-webkit-animation-duration": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["animation-duration"],
  },
  "-moz-animation-timing-function": {
    isInherited: false,
    values: [
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "inherit",
      "initial",
      "linear",
      "revert",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: ["animation-timing-function"],
  },
  "-webkit-animation-timing-function": {
    isInherited: false,
    values: [
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "inherit",
      "initial",
      "linear",
      "revert",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: ["animation-timing-function"],
  },
  "-moz-animation-iteration-count": {
    isInherited: false,
    values: ["infinite", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["animation-iteration-count"],
  },
  "-webkit-animation-iteration-count": {
    isInherited: false,
    values: ["infinite", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["animation-iteration-count"],
  },
  "-moz-animation-direction": {
    isInherited: false,
    values: [
      "alternate",
      "alternate-reverse",
      "inherit",
      "initial",
      "normal",
      "reverse",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["animation-direction"],
  },
  "-webkit-animation-direction": {
    isInherited: false,
    values: [
      "alternate",
      "alternate-reverse",
      "inherit",
      "initial",
      "normal",
      "reverse",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["animation-direction"],
  },
  "-moz-animation-play-state": {
    isInherited: false,
    values: ["inherit", "initial", "paused", "revert", "running", "unset"],
    supports: [],
    subproperties: ["animation-play-state"],
  },
  "-webkit-animation-play-state": {
    isInherited: false,
    values: ["inherit", "initial", "paused", "revert", "running", "unset"],
    supports: [],
    subproperties: ["animation-play-state"],
  },
  "-moz-animation-fill-mode": {
    isInherited: false,
    values: ["backwards", "both", "forwards", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["animation-fill-mode"],
  },
  "-webkit-animation-fill-mode": {
    isInherited: false,
    values: ["backwards", "both", "forwards", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["animation-fill-mode"],
  },
  "-moz-animation-delay": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["animation-delay"],
  },
  "-webkit-animation-delay": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["animation-delay"],
  },
  "-moz-transform": {
    isInherited: false,
    values: [
      "accumulatematrix",
      "inherit",
      "initial",
      "interpolatematrix",
      "matrix",
      "matrix3d",
      "none",
      "perspective",
      "revert",
      "rotate",
      "rotate3d",
      "rotateX",
      "rotateY",
      "rotateZ",
      "scale",
      "scale3d",
      "scaleX",
      "scaleY",
      "scaleZ",
      "skew",
      "skewX",
      "skewY",
      "translate",
      "translate3d",
      "translateX",
      "translateY",
      "translateZ",
      "unset",
    ],
    supports: [],
    subproperties: ["transform"],
  },
  "-webkit-transform": {
    isInherited: false,
    values: [
      "accumulatematrix",
      "inherit",
      "initial",
      "interpolatematrix",
      "matrix",
      "matrix3d",
      "none",
      "perspective",
      "revert",
      "rotate",
      "rotate3d",
      "rotateX",
      "rotateY",
      "rotateZ",
      "scale",
      "scale3d",
      "scaleX",
      "scaleY",
      "scaleZ",
      "skew",
      "skewX",
      "skewY",
      "translate",
      "translate3d",
      "translateX",
      "translateY",
      "translateZ",
      "unset",
    ],
    supports: [],
    subproperties: ["transform"],
  },
  "page-break-inside": {
    isInherited: false,
    values: ["auto", "avoid", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["break-inside"],
  },
  "-moz-perspective": {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["perspective"],
  },
  "-webkit-perspective": {
    isInherited: false,
    values: ["inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["perspective"],
  },
  "-moz-perspective-origin": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "left", "revert", "right", "top", "unset"],
    supports: [],
    subproperties: ["perspective-origin"],
  },
  "-webkit-perspective-origin": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "left", "revert", "right", "top", "unset"],
    supports: [],
    subproperties: ["perspective-origin"],
  },
  "-moz-backface-visibility": {
    isInherited: false,
    values: ["hidden", "inherit", "initial", "revert", "unset", "visible"],
    supports: [],
    subproperties: ["backface-visibility"],
  },
  "-webkit-backface-visibility": {
    isInherited: false,
    values: ["hidden", "inherit", "initial", "revert", "unset", "visible"],
    supports: [],
    subproperties: ["backface-visibility"],
  },
  "-moz-transform-style": {
    isInherited: false,
    values: ["flat", "inherit", "initial", "preserve-3d", "revert", "unset"],
    supports: [],
    subproperties: ["transform-style"],
  },
  "-webkit-transform-style": {
    isInherited: false,
    values: ["flat", "inherit", "initial", "preserve-3d", "revert", "unset"],
    supports: [],
    subproperties: ["transform-style"],
  },
  "-moz-transform-origin": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "left", "revert", "right", "top", "unset"],
    supports: [],
    subproperties: ["transform-origin"],
  },
  "-webkit-transform-origin": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "left", "revert", "right", "top", "unset"],
    supports: [],
    subproperties: ["transform-origin"],
  },
  "-webkit-appearance": {
    isInherited: false,
    values: [
      "-moz-gtk-info-bar",
      "-moz-mac-active-source-list-selection",
      "-moz-mac-disclosure-button-closed",
      "-moz-mac-disclosure-button-open",
      "-moz-mac-fullscreen-button",
      "-moz-mac-help-button",
      "-moz-mac-source-list",
      "-moz-mac-source-list-selection",
      "-moz-mac-vibrancy-dark",
      "-moz-mac-vibrancy-light",
      "-moz-mac-vibrant-titlebar-dark",
      "-moz-mac-vibrant-titlebar-light",
      "-moz-menulist-arrow-button",
      "-moz-win-borderless-glass",
      "-moz-win-browsertabbar-toolbox",
      "-moz-win-communications-toolbox",
      "-moz-win-exclude-glass",
      "-moz-win-glass",
      "-moz-win-media-toolbox",
      "-moz-window-button-box",
      "-moz-window-button-box-maximized",
      "-moz-window-button-close",
      "-moz-window-button-maximize",
      "-moz-window-button-minimize",
      "-moz-window-button-restore",
      "-moz-window-frame-bottom",
      "-moz-window-frame-left",
      "-moz-window-frame-right",
      "-moz-window-titlebar",
      "-moz-window-titlebar-maximized",
      "button",
      "button-arrow-down",
      "button-arrow-next",
      "button-arrow-previous",
      "button-arrow-up",
      "button-focus",
      "checkbox",
      "checkbox-container",
      "checkbox-label",
      "checkmenuitem",
      "dialog",
      "dualbutton",
      "groupbox",
      "inherit",
      "initial",
      "inner-spin-button",
      "listbox",
      "menuarrow",
      "menubar",
      "menucheckbox",
      "menuimage",
      "menuitem",
      "menuitemtext",
      "menulist",
      "menulist-button",
      "menulist-text",
      "menupopup",
      "menuradio",
      "menuseparator",
      "meter",
      "meterbar",
      "meterchunk",
      "none",
      "number-input",
      "progress-bar",
      "progressbar",
      "progressbar-vertical",
      "progresschunk",
      "radio",
      "radio-container",
      "radio-label",
      "radiomenuitem",
      "range",
      "range-thumb",
      "resizer",
      "resizerpanel",
      "revert",
      "scale-horizontal",
      "scale-vertical",
      "scalethumb-horizontal",
      "scalethumb-vertical",
      "scalethumbend",
      "scalethumbstart",
      "scalethumbtick",
      "scrollbar",
      "scrollbar-horizontal",
      "scrollbar-small",
      "scrollbar-vertical",
      "scrollbarbutton-down",
      "scrollbarbutton-left",
      "scrollbarbutton-right",
      "scrollbarbutton-up",
      "scrollbarthumb-horizontal",
      "scrollbarthumb-vertical",
      "scrollbartrack-horizontal",
      "scrollbartrack-vertical",
      "scrollcorner",
      "searchfield",
      "separator",
      "spinner",
      "spinner-downbutton",
      "spinner-textfield",
      "spinner-upbutton",
      "splitter",
      "statusbar",
      "statusbarpanel",
      "tab",
      "tab-scroll-arrow-back",
      "tab-scroll-arrow-forward",
      "tabpanel",
      "tabpanels",
      "textarea",
      "textfield",
      "textfield-multiline",
      "toolbar",
      "toolbarbutton",
      "toolbarbutton-dropdown",
      "toolbargripper",
      "toolbox",
      "tooltip",
      "treeheader",
      "treeheadercell",
      "treeheadersortarrow",
      "treeitem",
      "treeline",
      "treetwisty",
      "treetwistyopen",
      "treeview",
      "unset",
      "window",
    ],
    supports: [],
    subproperties: ["-moz-appearance"],
  },
  "-webkit-box-shadow": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "none",
      "revert",
      "rgb",
      "rgba",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: ["box-shadow"],
  },
  "-webkit-filter": {
    isInherited: false,
    values: [
      "blur",
      "brightness",
      "contrast",
      "drop-shadow",
      "grayscale",
      "hue-rotate",
      "inherit",
      "initial",
      "invert",
      "none",
      "opacity",
      "revert",
      "saturate",
      "sepia",
      "unset",
      "url",
    ],
    supports: [],
    subproperties: ["filter"],
  },
  "-moz-font-feature-settings": {
    isInherited: true,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["font-feature-settings"],
  },
  "-moz-font-language-override": {
    isInherited: true,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["font-language-override"],
  },
  "-moz-hyphens": {
    isInherited: true,
    values: ["auto", "inherit", "initial", "manual", "none", "revert", "unset"],
    supports: [],
    subproperties: ["hyphens"],
  },
  "-webkit-text-size-adjust": {
    isInherited: true,
    values: ["auto", "inherit", "initial", "none", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-text-size-adjust"],
  },
  "word-wrap": {
    isInherited: true,
    values: ["anywhere", "break-word", "inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["overflow-wrap"],
  },
  "-moz-margin-start": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-inline-start"],
  },
  "-moz-margin-end": {
    isInherited: false,
    values: ["auto", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["margin-inline-end"],
  },
  "-moz-padding-start": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-inline-start"],
  },
  "-moz-padding-end": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["padding-inline-end"],
  },
  "-webkit-flex-direction": {
    isInherited: false,
    values: [
      "column",
      "column-reverse",
      "inherit",
      "initial",
      "revert",
      "row",
      "row-reverse",
      "unset",
    ],
    supports: [],
    subproperties: ["flex-direction"],
  },
  "-webkit-flex-wrap": {
    isInherited: false,
    values: ["inherit", "initial", "nowrap", "revert", "unset", "wrap", "wrap-reverse"],
    supports: [],
    subproperties: ["flex-wrap"],
  },
  "-webkit-justify-content": {
    isInherited: false,
    values: [
      "center",
      "end",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "left",
      "normal",
      "revert",
      "right",
      "safe",
      "space-around",
      "space-between",
      "space-evenly",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["justify-content"],
  },
  "-webkit-align-content": {
    isInherited: false,
    values: [
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "normal",
      "revert",
      "safe",
      "space-around",
      "space-between",
      "space-evenly",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["align-content"],
  },
  "-webkit-align-items": {
    isInherited: false,
    values: [
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "normal",
      "revert",
      "safe",
      "self-end",
      "self-start",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["align-items"],
  },
  "-webkit-flex-grow": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["flex-grow"],
  },
  "-webkit-flex-shrink": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["flex-shrink"],
  },
  "-webkit-align-self": {
    isInherited: false,
    values: [
      "auto",
      "baseline",
      "center",
      "end",
      "first baseline",
      "flex-end",
      "flex-start",
      "inherit",
      "initial",
      "last baseline",
      "normal",
      "revert",
      "safe",
      "self-end",
      "self-start",
      "start",
      "stretch",
      "unsafe",
      "unset",
    ],
    supports: [],
    subproperties: ["align-self"],
  },
  "-webkit-order": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["order"],
  },
  "-webkit-flex-basis": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["flex-basis"],
  },
  "-moz-box-sizing": {
    isInherited: false,
    values: ["border-box", "content-box", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["box-sizing"],
  },
  "-webkit-box-sizing": {
    isInherited: false,
    values: ["border-box", "content-box", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["box-sizing"],
  },
  "grid-column-gap": {
    isInherited: false,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["column-gap"],
  },
  "grid-row-gap": {
    isInherited: false,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["row-gap"],
  },
  "-webkit-mask-repeat": {
    isInherited: false,
    values: [
      "inherit",
      "initial",
      "no-repeat",
      "repeat",
      "repeat-x",
      "repeat-y",
      "revert",
      "round",
      "space",
      "unset",
    ],
    supports: [],
    subproperties: ["mask-repeat"],
  },
  "-webkit-mask-position-x": {
    isInherited: false,
    values: ["center", "inherit", "initial", "left", "revert", "right", "unset"],
    supports: [],
    subproperties: ["mask-position-x"],
  },
  "-webkit-mask-position-y": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "revert", "top", "unset"],
    supports: [],
    subproperties: ["mask-position-y"],
  },
  "-webkit-mask-clip": {
    isInherited: false,
    values: [
      "border-box",
      "content-box",
      "fill-box",
      "inherit",
      "initial",
      "no-clip",
      "padding-box",
      "revert",
      "stroke-box",
      "unset",
      "view-box",
    ],
    supports: [],
    subproperties: ["mask-clip"],
  },
  "-webkit-mask-origin": {
    isInherited: false,
    values: [
      "border-box",
      "content-box",
      "fill-box",
      "inherit",
      "initial",
      "padding-box",
      "revert",
      "stroke-box",
      "unset",
      "view-box",
    ],
    supports: [],
    subproperties: ["mask-origin"],
  },
  "-webkit-mask-size": {
    isInherited: false,
    values: ["auto", "contain", "cover", "inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["mask-size"],
  },
  "-webkit-mask-composite": {
    isInherited: false,
    values: ["add", "exclude", "inherit", "initial", "intersect", "revert", "subtract", "unset"],
    supports: [],
    subproperties: ["mask-composite"],
  },
  "-webkit-mask-image": {
    isInherited: false,
    values: [
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "inherit",
      "initial",
      "linear-gradient",
      "none",
      "radial-gradient",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "unset",
      "url",
    ],
    supports: ["gradient"],
    subproperties: ["mask-image"],
  },
  "-moz-user-select": {
    isInherited: false,
    values: ["-moz-none", "all", "auto", "inherit", "initial", "none", "revert", "text", "unset"],
    supports: [],
    subproperties: ["user-select"],
  },
  "-webkit-user-select": {
    isInherited: false,
    values: ["-moz-none", "all", "auto", "inherit", "initial", "none", "revert", "text", "unset"],
    supports: [],
    subproperties: ["user-select"],
  },
  "-webkit-box-align": {
    isInherited: false,
    values: [
      "baseline",
      "center",
      "end",
      "inherit",
      "initial",
      "revert",
      "start",
      "stretch",
      "unset",
    ],
    supports: [],
    subproperties: ["-moz-box-align"],
  },
  "-webkit-box-direction": {
    isInherited: false,
    values: ["inherit", "initial", "normal", "reverse", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-box-direction"],
  },
  "-webkit-box-flex": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-box-flex"],
  },
  "-webkit-box-orient": {
    isInherited: false,
    values: [
      "block-axis",
      "horizontal",
      "inherit",
      "initial",
      "inline-axis",
      "revert",
      "unset",
      "vertical",
    ],
    supports: [],
    subproperties: ["-moz-box-orient"],
  },
  "-webkit-box-pack": {
    isInherited: false,
    values: ["center", "end", "inherit", "initial", "justify", "revert", "start", "unset"],
    supports: [],
    subproperties: ["-moz-box-pack"],
  },
  "-webkit-box-ordinal-group": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: ["-moz-box-ordinal-group"],
  },
  "-moz-border-start": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: [
      "border-inline-start-color",
      "border-inline-start-style",
      "border-inline-start-width",
    ],
  },
  "-moz-border-end": {
    isInherited: false,
    values: [
      "COLOR",
      "currentColor",
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "hsl",
      "hsla",
      "inherit",
      "initial",
      "inset",
      "medium",
      "none",
      "outset",
      "revert",
      "rgb",
      "rgba",
      "ridge",
      "solid",
      "thick",
      "thin",
      "transparent",
      "unset",
    ],
    supports: ["color"],
    subproperties: [
      "border-inline-end-color",
      "border-inline-end-style",
      "border-inline-end-width",
    ],
  },
  "-webkit-border-radius": {
    isInherited: false,
    values: ["inherit", "initial", "revert", "unset"],
    supports: [],
    subproperties: [
      "border-top-left-radius",
      "border-top-right-radius",
      "border-bottom-right-radius",
      "border-bottom-left-radius",
    ],
  },
  "-moz-border-image": {
    isInherited: false,
    values: [
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "auto",
      "fill",
      "inherit",
      "initial",
      "linear-gradient",
      "none",
      "radial-gradient",
      "repeat",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "round",
      "space",
      "stretch",
      "unset",
      "url",
    ],
    supports: ["gradient"],
    subproperties: [
      "border-image-outset",
      "border-image-repeat",
      "border-image-slice",
      "border-image-source",
      "border-image-width",
    ],
  },
  "-webkit-border-image": {
    isInherited: false,
    values: [
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "auto",
      "fill",
      "inherit",
      "initial",
      "linear-gradient",
      "none",
      "radial-gradient",
      "repeat",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "round",
      "space",
      "stretch",
      "unset",
      "url",
    ],
    supports: ["gradient"],
    subproperties: [
      "border-image-outset",
      "border-image-repeat",
      "border-image-slice",
      "border-image-source",
      "border-image-width",
    ],
  },
  "-moz-transition": {
    isInherited: false,
    values: [
      "all",
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "inherit",
      "initial",
      "linear",
      "none",
      "revert",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: [
      "transition-property",
      "transition-duration",
      "transition-timing-function",
      "transition-delay",
    ],
  },
  "-webkit-transition": {
    isInherited: false,
    values: [
      "all",
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "inherit",
      "initial",
      "linear",
      "none",
      "revert",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: [
      "transition-property",
      "transition-duration",
      "transition-timing-function",
      "transition-delay",
    ],
  },
  "-moz-animation": {
    isInherited: false,
    values: [
      "alternate",
      "alternate-reverse",
      "backwards",
      "both",
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "forwards",
      "infinite",
      "inherit",
      "initial",
      "linear",
      "none",
      "normal",
      "paused",
      "reverse",
      "revert",
      "running",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: [
      "animation-name",
      "animation-duration",
      "animation-timing-function",
      "animation-delay",
      "animation-iteration-count",
      "animation-direction",
      "animation-fill-mode",
      "animation-play-state",
    ],
  },
  "-webkit-animation": {
    isInherited: false,
    values: [
      "alternate",
      "alternate-reverse",
      "backwards",
      "both",
      "cubic-bezier",
      "ease",
      "ease-in",
      "ease-in-out",
      "ease-out",
      "forwards",
      "infinite",
      "inherit",
      "initial",
      "linear",
      "none",
      "normal",
      "paused",
      "reverse",
      "revert",
      "running",
      "step-end",
      "step-start",
      "steps",
      "unset",
    ],
    supports: ["timing-function"],
    subproperties: [
      "animation-name",
      "animation-duration",
      "animation-timing-function",
      "animation-delay",
      "animation-iteration-count",
      "animation-direction",
      "animation-fill-mode",
      "animation-play-state",
    ],
  },
  "-webkit-flex-flow": {
    isInherited: false,
    values: [
      "column",
      "column-reverse",
      "inherit",
      "initial",
      "nowrap",
      "revert",
      "row",
      "row-reverse",
      "unset",
      "wrap",
      "wrap-reverse",
    ],
    supports: [],
    subproperties: ["flex-direction", "flex-wrap"],
  },
  "-webkit-flex": {
    isInherited: false,
    values: [
      "-moz-available",
      "-moz-fit-content",
      "-moz-max-content",
      "-moz-min-content",
      "auto",
      "content",
      "inherit",
      "initial",
      "max-content",
      "min-content",
      "revert",
      "unset",
    ],
    supports: [],
    subproperties: ["flex-grow", "flex-shrink", "flex-basis"],
  },
  "grid-gap": {
    isInherited: false,
    values: ["inherit", "initial", "normal", "revert", "unset"],
    supports: [],
    subproperties: ["row-gap", "column-gap"],
  },
  "-webkit-mask": {
    isInherited: false,
    values: [
      "-moz-element",
      "-moz-image-rect",
      "-moz-linear-gradient",
      "-moz-radial-gradient",
      "-moz-repeating-linear-gradient",
      "-moz-repeating-radial-gradient",
      "-webkit-gradient",
      "-webkit-linear-gradient",
      "-webkit-radial-gradient",
      "-webkit-repeating-linear-gradient",
      "-webkit-repeating-radial-gradient",
      "add",
      "alpha",
      "auto",
      "border-box",
      "bottom",
      "center",
      "contain",
      "content-box",
      "cover",
      "exclude",
      "fill-box",
      "inherit",
      "initial",
      "intersect",
      "left",
      "linear-gradient",
      "luminance",
      "match-source",
      "no-clip",
      "no-repeat",
      "none",
      "padding-box",
      "radial-gradient",
      "repeat",
      "repeat-x",
      "repeat-y",
      "repeating-linear-gradient",
      "repeating-radial-gradient",
      "revert",
      "right",
      "round",
      "space",
      "stroke-box",
      "subtract",
      "top",
      "unset",
      "url",
      "view-box",
    ],
    supports: ["gradient"],
    subproperties: [
      "mask-mode",
      "mask-repeat",
      "mask-clip",
      "mask-origin",
      "mask-composite",
      "mask-position-x",
      "mask-position-y",
      "mask-size",
      "mask-image",
    ],
  },
  "-webkit-mask-position": {
    isInherited: false,
    values: ["bottom", "center", "inherit", "initial", "left", "revert", "right", "top", "unset"],
    supports: [],
    subproperties: ["mask-position-x", "mask-position-y"],
  },
};

const NON_ASCII = "[^\\x00-\\x7F]";
const ESCAPE = "\\\\[^\n\r]";
const FIRST_CHAR = ["[_a-z]", NON_ASCII, ESCAPE].join("|");
const TRAILING_CHAR = ["[_a-z0-9-]", NON_ASCII, ESCAPE].join("|");
const IS_VARIABLE_TOKEN = new RegExp(`^--(${FIRST_CHAR})(${TRAILING_CHAR})*$`, "i");

function isCssVariable(input) {
  return !!input.match(IS_VARIABLE_TOKEN);
}

const CSSProperties = {
  supportsType(property, type) {
    return properties[property]?.supports.includes(type) || false;
  },

  supportsCssColor4ColorFunction() {
    return true;
  },

  getSubproperties(name) {
    // Custom Property Names (aka CSS Variables) are case-sensitive; do not lowercase.
    name = name.startsWith("--") ? name : name.toLowerCase();
    if (this.isKnown(name)) {
      if (properties[name]?.subproperties) {
        return properties[name].subproperties;
      }
      return [name];
    }
    return [];
  },

  isKnown(property) {
    // Custom Property Names (aka CSS Variables) are case-sensitive; do not lowercase.
    property = property.startsWith("--") ? property : property.toLowerCase();
    return !!properties[property] || isCssVariable(property);
  },

  isInherited(property) {
    return properties[property]?.isInherited || isCssVariable(property);
  },
};

export default CSSProperties;

/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

"use strict";

// /!\  Auto-generated from nsColorNameList.h.
// This should be kept in sync with that list.
// test_cssColorDatabase.js tries to enforce this.

export const cssColors = {
  aliceblue: [240, 248, 255, 1],
  antiquewhite: [250, 235, 215, 1],
  aqua: [0, 255, 255, 1],
  aquamarine: [127, 255, 212, 1],
  azure: [240, 255, 255, 1],
  beige: [245, 245, 220, 1],
  bisque: [255, 228, 196, 1],
  black: [0, 0, 0, 1],
  blanchedalmond: [255, 235, 205, 1],
  blue: [0, 0, 255, 1],
  blueviolet: [138, 43, 226, 1],
  brown: [165, 42, 42, 1],
  burlywood: [222, 184, 135, 1],
  cadetblue: [95, 158, 160, 1],
  chartreuse: [127, 255, 0, 1],
  chocolate: [210, 105, 30, 1],
  coral: [255, 127, 80, 1],
  cornflowerblue: [100, 149, 237, 1],
  cornsilk: [255, 248, 220, 1],
  crimson: [220, 20, 60, 1],
  cyan: [0, 255, 255, 1],
  darkblue: [0, 0, 139, 1],
  darkcyan: [0, 139, 139, 1],
  darkgoldenrod: [184, 134, 11, 1],
  darkgray: [169, 169, 169, 1],
  darkgreen: [0, 100, 0, 1],
  darkgrey: [169, 169, 169, 1],
  darkkhaki: [189, 183, 107, 1],
  darkmagenta: [139, 0, 139, 1],
  darkolivegreen: [85, 107, 47, 1],
  darkorange: [255, 140, 0, 1],
  darkorchid: [153, 50, 204, 1],
  darkred: [139, 0, 0, 1],
  darksalmon: [233, 150, 122, 1],
  darkseagreen: [143, 188, 143, 1],
  darkslateblue: [72, 61, 139, 1],
  darkslategray: [47, 79, 79, 1],
  darkslategrey: [47, 79, 79, 1],
  darkturquoise: [0, 206, 209, 1],
  darkviolet: [148, 0, 211, 1],
  deeppink: [255, 20, 147, 1],
  deepskyblue: [0, 191, 255, 1],
  dimgray: [105, 105, 105, 1],
  dimgrey: [105, 105, 105, 1],
  dodgerblue: [30, 144, 255, 1],
  firebrick: [178, 34, 34, 1],
  floralwhite: [255, 250, 240, 1],
  forestgreen: [34, 139, 34, 1],
  fuchsia: [255, 0, 255, 1],
  gainsboro: [220, 220, 220, 1],
  ghostwhite: [248, 248, 255, 1],
  gold: [255, 215, 0, 1],
  goldenrod: [218, 165, 32, 1],
  gray: [128, 128, 128, 1],
  grey: [128, 128, 128, 1],
  green: [0, 128, 0, 1],
  greenyellow: [173, 255, 47, 1],
  honeydew: [240, 255, 240, 1],
  hotpink: [255, 105, 180, 1],
  indianred: [205, 92, 92, 1],
  indigo: [75, 0, 130, 1],
  ivory: [255, 255, 240, 1],
  khaki: [240, 230, 140, 1],
  lavender: [230, 230, 250, 1],
  lavenderblush: [255, 240, 245, 1],
  lawngreen: [124, 252, 0, 1],
  lemonchiffon: [255, 250, 205, 1],
  lightblue: [173, 216, 230, 1],
  lightcoral: [240, 128, 128, 1],
  lightcyan: [224, 255, 255, 1],
  lightgoldenrodyellow: [250, 250, 210, 1],
  lightgray: [211, 211, 211, 1],
  lightgreen: [144, 238, 144, 1],
  lightgrey: [211, 211, 211, 1],
  lightpink: [255, 182, 193, 1],
  lightsalmon: [255, 160, 122, 1],
  lightseagreen: [32, 178, 170, 1],
  lightskyblue: [135, 206, 250, 1],
  lightslategray: [119, 136, 153, 1],
  lightslategrey: [119, 136, 153, 1],
  lightsteelblue: [176, 196, 222, 1],
  lightyellow: [255, 255, 224, 1],
  lime: [0, 255, 0, 1],
  limegreen: [50, 205, 50, 1],
  linen: [250, 240, 230, 1],
  magenta: [255, 0, 255, 1],
  maroon: [128, 0, 0, 1],
  mediumaquamarine: [102, 205, 170, 1],
  mediumblue: [0, 0, 205, 1],
  mediumorchid: [186, 85, 211, 1],
  mediumpurple: [147, 112, 219, 1],
  mediumseagreen: [60, 179, 113, 1],
  mediumslateblue: [123, 104, 238, 1],
  mediumspringgreen: [0, 250, 154, 1],
  mediumturquoise: [72, 209, 204, 1],
  mediumvioletred: [199, 21, 133, 1],
  midnightblue: [25, 25, 112, 1],
  mintcream: [245, 255, 250, 1],
  mistyrose: [255, 228, 225, 1],
  moccasin: [255, 228, 181, 1],
  navajowhite: [255, 222, 173, 1],
  navy: [0, 0, 128, 1],
  oldlace: [253, 245, 230, 1],
  olive: [128, 128, 0, 1],
  olivedrab: [107, 142, 35, 1],
  orange: [255, 165, 0, 1],
  orangered: [255, 69, 0, 1],
  orchid: [218, 112, 214, 1],
  palegoldenrod: [238, 232, 170, 1],
  palegreen: [152, 251, 152, 1],
  paleturquoise: [175, 238, 238, 1],
  palevioletred: [219, 112, 147, 1],
  papayawhip: [255, 239, 213, 1],
  peachpuff: [255, 218, 185, 1],
  peru: [205, 133, 63, 1],
  pink: [255, 192, 203, 1],
  plum: [221, 160, 221, 1],
  powderblue: [176, 224, 230, 1],
  purple: [128, 0, 128, 1],
  rebeccapurple: [102, 51, 153, 1],
  red: [255, 0, 0, 1],
  rosybrown: [188, 143, 143, 1],
  royalblue: [65, 105, 225, 1],
  saddlebrown: [139, 69, 19, 1],
  salmon: [250, 128, 114, 1],
  sandybrown: [244, 164, 96, 1],
  seagreen: [46, 139, 87, 1],
  seashell: [255, 245, 238, 1],
  sienna: [160, 82, 45, 1],
  silver: [192, 192, 192, 1],
  skyblue: [135, 206, 235, 1],
  slateblue: [106, 90, 205, 1],
  slategray: [112, 128, 144, 1],
  slategrey: [112, 128, 144, 1],
  snow: [255, 250, 250, 1],
  springgreen: [0, 255, 127, 1],
  steelblue: [70, 130, 180, 1],
  tan: [210, 180, 140, 1],
  teal: [0, 128, 128, 1],
  thistle: [216, 191, 216, 1],
  tomato: [255, 99, 71, 1],
  turquoise: [64, 224, 208, 1],
  violet: [238, 130, 238, 1],
  wheat: [245, 222, 179, 1],
  white: [255, 255, 255, 1],
  whitesmoke: [245, 245, 245, 1],
  yellow: [255, 255, 0, 1],
  yellowgreen: [154, 205, 50, 1],
};

// Lab values generated using formula from http://www.easyrgb.com/en/math.php.
// X_10, Y_10, Z_10 (CIE 1964) reference values for D65 illuminant
// (Daylight, sRGB, Adobe-RGB) were used.
export const labColors = {
  aliceblue: [97.17890760827636, -0.9397756746095665, -5.246475627032843],
  antiquewhite: [93.73077088204487, 2.236373679139203, 10.649604819299775],
  aqua: [91.11652110946342, -47.73670577664391, -15.108617112376965],
  aquamarine: [92.03615371984776, -45.172410289053445, 8.849868350925295],
  azure: [98.93278063011066, -4.468521315692032, -2.6738855376031267],
  beige: [95.9488798865349, -3.7925176714859177, 11.156542330042662],
  bisque: [92.0124490871158, 4.82408891282099, 18.18700912515505],
  black: [0, 0, 0],
  blanchedalmond: [93.91948969581235, 2.528359387620638, 16.175210959201024],
  blue: [32.302586667249486, 79.43492388715862, -108.79669359538693],
  blueviolet: [42.18810476642369, 70.12395877232036, -75.62885995423268],
  brown: [37.521829744034335, 49.932241625757406, 30.23890786161597],
  burlywood: [77.01689872654846, 7.390609044482909, 29.376190715516536],
  cadetblue: [61.15461539950293, -19.415767005661277, -8.112231649104906],
  chartreuse: [89.87420853068858, -67.74722658691745, 85.31006761317337],
  chocolate: [55.98605299432204, 37.34619405020917, 56.41475927486582],
  coral: [67.29048083264607, 45.696749904176436, 47.02073461855407],
  cornflowerblue: [61.92818670495679, 9.628987440020332, -50.20264083555141],
  cornsilk: [97.45526614880224, -1.8103271448461355, 13.39924085969022],
  crimson: [47.02980511087301, 71.21981659922766, 33.22842672949625],
  cyan: [91.11652110946342, -47.73670577664391, -15.108617112376965],
  darkblue: [14.757156815274186, 50.58098497783475, -69.27738650827831],
  darkcyan: [52.207519815998296, -30.3968264791159, -9.620563573304297],
  darkgoldenrod: [59.2185428516686, 10.142719410772493, 62.4074048759456],
  darkgray: [69.23779560557699, 0.30845909288851336, -0.725035226776094],
  darkgreen: [36.20351872497333, -43.22004822584333, 41.62418791981264],
  darkgrey: [69.23779560557699, 0.30845909288851336, -0.725035226776094],
  darkkhaki: [73.38127833356914, -8.475096082350364, 38.72716132653318],
  darkmagenta: [32.59748369188066, 62.78988696261057, -39.34048012552456],
  darkolivegreen: [42.2340254244788, -18.63594475603103, 30.256085481863913],
  darkorange: [69.48104411888188, 37.16688167941806, 75.14386507030734],
  darkorchid: [43.37926695780136, 65.43267508264566, -60.90252187934995],
  darkred: [28.084732284208997, 51.2102293904998, 41.189776600752445],
  darksalmon: [69.85346453844176, 28.509213259811705, 27.1173578088314],
  darkseagreen: [72.08740631745533, -23.522932623842696, 17.379064372791554],
  darkslateblue: [30.829287209616794, 26.247343305352388, -42.688380574102695],
  darkslategray: [31.25607829935253, -11.558209436609623, -4.143133638795559],
  darkslategrey: [31.25607829935253, -11.558209436609623, -4.143133638795559],
  darkturquoise: [75.29307531314078, -39.74330458218445, -14.353628151972542],
  darkviolet: [39.57886745488795, 76.59826422688023, -71.18960396554851],
  deeppink: [55.95428053659428, 84.8834888580915, -6.348160442712891],
  deepskyblue: [72.54923231908798, -17.34624248545108, -43.501223759829614],
  dimgray: [44.41356014781601, 0.21862498706087807, -0.513879541005613],
  dimgrey: [44.41356014781601, 0.21862498706087807, -0.513879541005613],
  dodgerblue: [59.381725677880596, 10.24722110279519, -64.33873493803226],
  firebrick: [39.11257274771978, 56.17110340915579, 37.36856201227712],
  floralwhite: [98.40143252802201, 0.37693631302881947, 4.4296183363891295],
  forestgreen: [50.59443111773345, -49.389501730047996, 44.675247183324544],
  fuchsia: [60.319933664076004, 98.60839787805526, -61.78226947982015],
  gainsboro: [87.76088811005116, 0.3754905813360132, -0.8825932030137817],
  ghostwhite: [97.7572735023453, 1.6602117625886814, -4.329673676949475],
  gold: [86.9285847161576, -1.5580031594539245, 86.69625851871493],
  goldenrod: [70.81571317667877, 8.842268163696321, 68.36969220318736],
  gray: [53.585013452169036, 0.2518147023348183, -0.5918921958278478],
  grey: [53.585013452169036, 0.2518147023348183, -0.5918921958278478],
  green: [46.22881784262658, -51.520138371609654, 49.61780491379323],
  greenyellow: [91.95763180408608, -52.141510237787294, 81.35705222692138],
  honeydew: [98.56580137512647, -7.15743440652189, 4.528336799358468],
  hotpink: [65.48186958181394, 64.59515848582348, -11.397593830097975],
  indianred: [53.3911485087161, 45.12118404819798, 21.633935524110235],
  indigo: [20.46961954096429, 51.86802044661362, -53.88700686969674],
  ivory: [99.63977381109996, -2.1358046127358254, 6.214520241062194],
  khaki: [90.32729582034386, -8.636557330944761, 44.29884008040395],
  lavender: [91.82769059829121, 4.102249854337825, -10.62634890559453],
  lavenderblush: [96.06837944039779, 6.29813340018881, -1.5503003307978824],
  lawngreen: [88.87798766318886, -67.54051106102659, 84.48686421833247],
  lemonchiffon: [97.6476983766357, -5.022220605824412, 21.377483194761602],
  lightblue: [83.81410256512628, -10.538570949192195, -12.381398199756767],
  lightcoral: [66.15316216626996, 43.146382854120255, 18.95210780303691],
  lightcyan: [97.86814756512815, -9.540634546450377, -4.3595592568243635],
  lightgoldenrodyellow: [97.36879732328356, -6.078213524913445, 18.368452638973686],
  lightgray: [84.5561167363605, 0.36389313370355225, -0.8553333223096704],
  lightgreen: [86.54957590580997, -45.99957827642365, 36.262226875570526],
  lightgrey: [84.5561167363605, 0.36389313370355225, -0.8553333223096704],
  lightpink: [81.05253594361857, 28.338879720153866, 4.233248736979411],
  lightsalmon: [74.70287844017, 31.832905786707443, 33.947175894873574],
  lightseagreen: [65.78768757989991, -37.24749389368925, -7.0553625579029555],
  lightskyblue: [79.72503275959482, -10.490259345458607, -29.455091101479304],
  lightslategray: [55.917349372007195, -1.9881568917105574, -11.774175139854615],
  lightslategrey: [55.917349372007195, -1.9881568917105574, -11.774175139854615],
  lightsteelblue: [78.45233742822654, -0.9392458669100212, -16.08913657631925],
  lightyellow: [99.28483637693047, -4.695918870143368, 13.930783736002962],
  lime: [87.73703347354422, -85.88539046866318, 82.71415186197115],
  limegreen: [72.60854102811317, -66.86592102874306, 60.99160141799125],
  linen: [95.31120096478845, 2.0811899572744097, 5.104906068897774],
  magenta: [60.319933664076004, 98.60839787805526, -61.78226947982015],
  maroon: [25.530784572416174, 48.24348236957932, 37.97194526317749],
  mediumaquamarine: [75.6931293906138, -38.036553589818666, 7.571354765719218],
  mediumblue: [24.97615723043893, 67.38641044652589, -92.29465191227868],
  mediumorchid: [53.64213313573549, 59.370412003217574, -48.23242669563843],
  mediumpurple: [54.97523587308039, 37.09186250486124, -50.94130373794006],
  mediumseagreen: [65.27341001550978, -47.96643007935963, 23.721501577817094],
  mediumslateblue: [52.15750471109439, 41.35727328020744, -66.29828530971085],
  mediumspringgreen: [87.3411479783456, -70.37364235921007, 31.74790377903216],
  mediumturquoise: [76.8834686242291, -37.053452849746925, -9.183510559800713],
  mediumvioletred: [44.76162545138306, 71.28274660137451, -15.767834030225348],
  midnightblue: [15.859552026439893, 31.860128204191234, -50.08845915164022],
  mintcream: [99.15659662605985, -3.7499332041522893, 0.27328356915989893],
  mistyrose: [92.65558901260384, 9.147609792762356, 3.9347415624175097],
  moccasin: [91.72208952705464, 2.8291906794328248, 25.573953055107125],
  navajowhite: [90.10007935356519, 4.895862633988224, 27.509525419099212],
  navy: [12.975311577716514, 47.65069178672376, -65.26396023609063],
  oldlace: [96.77967120416257, 0.5784959274544033, 7.247216630469544],
  olive: [51.86833136334822, -12.698958090622824, 56.38276716821539],
  olivedrab: [54.650773976467136, -27.99433891007591, 49.33893945670905],
  orange: [74.93219484533535, 24.28083176103374, 78.57643571179258],
  orangered: [57.57499421872107, 68.11461778155586, 68.68889341848478],
  orchid: [62.8009876658991, 55.621167174625064, -35.24868649820956],
  palegoldenrod: [91.14038954621267, -6.970467434592564, 30.213907174487264],
  palegreen: [90.75103714758299, -47.95503269332063, 37.81303534889619],
  paleturquoise: [90.06142407788806, -19.270453187271997, -7.332269935129032],
  palevioletred: [60.564787867661266, 45.838854731426515, -0.25065577032372666],
  papayawhip: [95.07544110280504, 1.6725420650229306, 13.652324626448543],
  peachpuff: [89.34875285782702, 8.471965039192453, 20.229897875280955],
  peru: [61.751663684265665, 21.6941378210313, 47.496113355477334],
  pink: [83.58479885775868, 24.525540181552508, 2.4934778968259685],
  plum: [73.37274280928348, 32.885443900333215, -22.856650741159747],
  powderblue: [86.13359115282357, -13.734412712450183, -8.914802708654769],
  purple: [29.782100092098077, 59.15229907621816, -37.06137976602428],
  rebeccapurple: [32.90243720302775, 43.10251060523782, -47.79866704780537],
  red: [53.23288178584245, 80.42312097443104, 66.96552840294578],
  osybrown: [63.6060381979233, 17.31557480446677, 5.964013673186241],
  royalblue: [47.83284750656463, 26.523923842683427, -66.12638984604963],
  saddlebrown: [37.46692024400534, 26.659518429642898, 40.73548402580912],
  salmon: [67.25995258561211, 45.569072753010865, 28.527978009408052],
  sandybrown: [73.95154071958171, 23.37100127546693, 46.25858205082345],
  seagreen: [51.53535112854968, -39.50584886698982, 19.578964107165852],
  seashell: [97.12111642889012, 2.5730542225586572, 3.614216901880374],
  sienna: [43.796139581025685, 29.564844221078523, 35.307185975723435],
  silver: [77.7043635899527, 0.339097964550894, -0.7970521060836511],
  skyblue: [79.20897459092869, -14.504258485788124, -22.18979862749155],
  slateblue: [45.33730589003685, 36.29854743329886, -58.578448229379696],
  slategray: [52.83625796271889, -1.894387969799849, -11.208348578862527],
  slategrey: [52.83625796271889, -1.894387969799849, -11.208348578862527],
  snow: [98.64376394836441, 2.073027231003055, -0.3849225040892579],
  springgreen: [88.47265520282772, -76.59140183507563, 46.37560569696295],
  steelblue: [52.46747241512048, -3.828873386915843, -32.93153861676517],
  tan: [74.97454643298407, 5.353040309039603, 23.775061853275446],
  teal: [48.25607381337552, -28.635856151982775, -9.063218319038114],
  thistle: [80.07734471203901, 13.57781031097305, -10.092571203748268],
  tomato: [62.20136881808274, 58.18874059980511, 45.985101486056514],
  turquoise: [81.26705459794431, -43.765218870671475, -4.872758326234994],
  violet: [69.69362286537107, 56.72140058020769, -37.7245806568902],
  wheat: [89.35068099788126, 1.8921211283112749, 23.23051272657026],
  white: [100, 0.41978155958710683, -0.9866994530830286],
  whitesmoke: [96.53748961423615, 0.40725140432995577, -0.9572472366698781],
  yellow: [97.13824698129729, -21.169488449187533, 93.99151723968109],
  yellowgreen: [76.5351984302265, -37.69097531845872, 66.13250570541341],
};
